import React, { useState } from 'react';
import { Button, ToggleButton, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';




const ButtonGrid: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        flexDirection="row"
        flexWrap="wrap"
        sx={{ gap: 2, padding: 2 }}
        >
        <Button
        variant="contained"
        sx={{
        flexGrow: 6,
        minWidth: 100,
        paddingY: 2,
        textAlign: 'center',
        background: 'linear-gradient(90deg, #b62a73 1%, #ffcdb3 100%)',
        }}
        onClick={() => {navigate('/shopowner/settings')}}
    >
        פרטי חנות
    </Button>
        <Button
            variant="contained"
            sx={{
            flexGrow: 6,
            minWidth: 100,
            paddingY: 2,
            textAlign: 'center',
            background: 'linear-gradient(90deg, #b62a73 1%, #ffcdb3 100%)',
            }}
            onClick={() => {navigate('/shopowner/hours')}}
        >
            שעות חנות
        </Button>
        <Button
            variant="contained"
            sx={{
            flexGrow: 6,
            minWidth: 100,
            paddingY: 2,
            textAlign: 'center',
            background: 'linear-gradient(90deg, #b62a73 1%, #ffcdb3 100%)',
            }}
            onClick={() => {navigate('/shopowner/services')}}
        >
            שירותים
        </Button>
        </Box>
    );
    };

export default ButtonGrid;