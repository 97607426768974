import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject  } from 'firebase/storage';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const firebaseConfig = {
    apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
    authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
    projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
    appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
    measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`
};

export const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);



export function useAuth() {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
        setUser(user);
        setLoading(false);
        if (!user) {
            navigate('/login');
        }
        });

        return unsubscribe;
    }, [navigate]);

    return { user, loading };
}

export const deleteFileByDownloadUrl = async (downloadUrl) => {
  try {
    const storage = getStorage();
    // Extract the file path from the download URL
    const filePathStart = downloadUrl.indexOf('/o/') + 3;
    const filePathEnd = downloadUrl.indexOf('?alt=media');
    const filePath = decodeURIComponent(downloadUrl.slice(filePathStart, filePathEnd));

    // Create a reference to the file
    const fileRef = ref(storage, filePath);

    // Delete the file
    await deleteObject(fileRef);
    console.log('File deleted successfully');
  } catch (error) {
    // console.error('Error deleting file:', error);
    return
  }
};

export const handleFileUpload = async (files, setImageUrl) => {
    // const files = event.target.files;
    
    if (files && files[0]) {
      const file = files[0];
      
      try {
        // Get a reference to Firebase Storage
        const storage = getStorage(app);
        
        // Create a reference to the file location
        const storageRef = ref(storage, `images/${Date.now()}_${file.name}`);
        
        // Upload the file
        const snapshot = await uploadBytes(storageRef, file);
        
        // Get the download URL
        const downloadURL = await getDownloadURL(snapshot.ref);
        
        // Set the image URL in state
        setImageUrl(downloadURL);
        
        console.log('File uploaded successfully', downloadURL);
      } catch (error) {
        console.error('Upload failed', error);
      }
    }
  };


export const db = getFirestore(app);
export const firebaseStorage = getStorage(app);

