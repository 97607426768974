import React, { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import ResponsiveAppBar from '../../components/appbar/Appbar';
import { useNavigate } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list';
import rrulePlugin from '@fullcalendar/rrule'
import interactionPlugin from '@fullcalendar/interaction'
import { getAppointmentInfo, deleteAppointment, getUserAppointments } from '../../resources/appointmentsDB/AppointmentsDB';
import { getPublicShopInfo, getShopWeeklySchedule, ShopInfo } from '../../resources/shopsDB/ShopsDB';
import momentPlugin from '@fullcalendar/moment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';







const AccountPage: React.FC = () => {
    const slotGranularity = 10
    const calendarRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    // const [focusDate, setFocusDate] = useState(null);
    // const [shopId, setShopId] = useState(shopId);
    const [currentEvents, setCurrentEvents] = useState([]);
    // const [userEvents, setUserEvents] = useState<any>([]);
    // const [selectedEvents, setSelectedEvents] = useState<any>([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [confirmWindowOpen, setConfirmWindowOpen] = useState(false);
    const [confirmWindowMessage, setConfirmWindowMessage] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    
    const [eventSelected, setEventSelected] = useState(null);


    const navigate = useNavigate();

    useEffect(() => {
        fetchEvents();
      }, []);
    
      // useEffect(() => {
      //   setCurrentEvents([...userEvents,...selectedEvents])
      // }, [userEvents,selectedEvents]);

    useEffect(() => {
      setIsLoading(false);
    }, [currentEvents]);

    useEffect(() => {
      if (eventSelected) {
        setDialogOpen(true);
      }
    }, [eventSelected]);
    
      // useEffect(() => {
      //   if (focusDate && calendarRef.current) {
      //     const calendarApi = calendarRef.current.getApi();
      //     calendarApi.gotoDate(focusDate);
      //     setFocusDate(null); // Reset focus date after navigating
      //   }
      // }, [focusDate]);
      
      const handleDialogClose = () => {
        setDialogOpen(false);
      };
    const fetchEvents = async () => {
        // const shopHours = await getShopAvailableHours(shopId)
        // const publicAppointments = await getAppointmentInfo(shopId)
        var events_list = await getUserAppointments()
        await setCurrentEvents(events_list)
    }
      
    const handleEventClick = async (clickInfo: any) => {
        setEventSelected(clickInfo.event)      
    }
    
    
    
      const renderEventContent = (eventInfo: any) => {
        return (
          <>
            <i>{eventInfo.event.title}</i>
            <br />
            <i>{eventInfo.event.extendedProps.service_name}</i>
            <br />
            {eventInfo.event.extendedProps.client_requests}
          </>
        )
      }

    const dayCellDidMount = (arg) => {
        const dateNumber = arg.el.querySelector('.fc-daygrid-day-number');
        if (dateNumber) {
          dateNumber.style.pointerEvents = 'none';
          dateNumber.style.cursor = 'default';
        }
        else {
          return
        }
        const { date, el } = arg;
        // const dayEvents = currentEvents.filter(event => 
        //   event.start.toDateString() === date.toDateString()
        // );
    
        if (currentEvents.length > 0) {
          const allAvailable = currentEvents.every(event => (event as any).title! == 'available');
          const someAvailable = currentEvents.some(event => (event as any).title! == 'available');
    
          if (allAvailable) {
            el.classList.add('fc-day-all-available');
          } else if (someAvailable) {
            el.classList.add('fc-day-some-available');
          } else {
             el.classList.add('fc-day-none-available');
          }
        }
      };

      const handleDateSelect = async (selectInfo: any) => {
        if (selectInfo.view.type === 'dayGridMonth') {
          let calendarApi = selectInfo.view.calendar
          calendarApi.changeView('timeGridDay', selectInfo.startStr);
          return
    
        }
    
        // if (checkAvailableEventsAtTime(selectInfo.date).length === 0) {
        //   return
        // }
    
        // if (!checkEventinShopHours(selectInfo.date)) {
        //     setSnackbarOpen(true)
        //   return
        // }
    
        // if (checkEventsAtTime(selectInfo.date)) {
        //   return
        // }
    
        
        
        
    
        // // var startTime = selectInfo.date.toLocaleTimeString()
    
        // var endTimeDate = GetEndAppointmentDate(selectInfo.date, selectedService.time_length)
        
    
        // setConfirmWindowMessage('Book ' + selectedService.name + ' between \n' + selectInfo.date.toTimeString().split(' ')[0].slice(0,-3) + ' to ' + endTimeDate.toTimeString().split(' ')[0].slice(0,-3))
        
        // // let confirmSlot = window.confirm('Would you like to book an appointment between \n' + selectInfo.date.toTimeString().split(' ')[0].slice(0,-3) + ' to ' + endTimeDate.toTimeString().split(' ')[0].slice(0,-3))
    
        let calendarApi = selectInfo.view.calendar
    
        calendarApi.unselect() // clear date selection
        setConfirmWindowOpen(true)
    
        if (true) {
          return
        }
      }
    if (isLoading) {
        return <div>Loading events...</div>;
    }
    else{
        return (
            <div>
                <ResponsiveAppBar />
                <React.Fragment>
                  <Dialog
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    // PaperProps={{
                    //   component: 'form',
                    //   onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
                    //     event.preventDefault();
                    //     const formData = new FormData(event.currentTarget);
                    //     const formJson = Object.fromEntries((formData as any).entries());
                    //     const shopService = {description: formJson.description ? formJson.description : "", image: imageUrl, name: formJson.name, price: formJson.price, time_length: timeLength} as Service;
                    //     try{
                    //       await addShopService(shopService)
                    //     }catch(e){
                    //       console.log(e)
                    //       await deleteFileByDownloadUrl(imageUrl);
                    //     }
                    //     window.location.reload();
                    //   },
                    // }}
                  >
                    {/* <DialogTitle>הוסף שירות</DialogTitle> */}
                    <DialogContent>
                    <Button onClick={() => {navigate(`/shop/${eventSelected.extendedProps.shop_id}`)}}>Jump to store</Button>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => {
                        handleDialogClose()}}>ביטול</Button>
                      <Button type="submit">הוסף שירות</Button>
                    </DialogActions>
                  </Dialog>
                </React.Fragment>
                <Box
                        display="flex"
                        justifyContent="space-around"
                        alignItems="center"
                        flexDirection="column"
                        flexWrap="wrap"
                        sx={{ gap: 2, padding: 2 }}
                >
                <Button onClick={() => {navigate("/account/details")}}
                                variant="contained"
                                sx={{
                                flexGrow: 6,
                                minWidth: 100,
                                textAlign: 'center',
                                background: 'linear-gradient(90deg, #b62a73 1%, #ffcdb3 100%)',
                                }}
                                >
                                אזור אישי
                </Button>
                </Box>
                <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, rrulePlugin, momentPlugin]}
                locale={'en-gb'}
                titleFormat={'YYYY'}
                headerToolbar={{
                left: 'prev,next',
                center: 'title',
                right: 'today',
                }}
                initialView='listYear'
                eventTimeFormat={{
                hour: 'numeric',
                minute: '2-digit',
                meridiem: true
                }}
                editable={true}
                selectable={true}
                // selectAllow={handleSelectAllow} // Allow selection based on custom logic
                selectMirror={true}
                dayMaxEvents={true}
                events={currentEvents}
                slotDuration={{ minutes: slotGranularity }}
                // initialEvents={currentEvents} // alternatively, use the `events` setting to fetch from a feed
                dateClick={handleDateSelect}
                select={handleDateSelect}

                eventContent={renderEventContent} // custom render function
                dayMaxEventRows={0} // This prevents the "+X more" link from appearing
                eventClick={handleEventClick}
                // eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                slotMinTime="08:00:00" // Show only from 8:00 AM
                slotMaxTime="22:00:00" // Show only until 10:00 PM
                slotLabelInterval={{ minutes: slotGranularity }} // Show labels for every hour
                allDaySlot={false}
                navLinks={true}
                selectLongPressDelay={0}
                height="auto"
                aspectRatio={1.35}
                dayCellDidMount={dayCellDidMount}
                // eventDidMount={handleEventDidMount}
                eventStartEditable={false}
                eventResizableFromStart={false}
                eventDurationEditable={false}
                
            />
            </div>
        );
    }
}

export default AccountPage;