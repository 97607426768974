import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../components/auth/firebase';
import { storeUserInfo } from '../../resources/usersDB/UsersDB';
import { 
  Button, 
  TextField, 
  Container, 
  Typography, 
  Box,
  Paper,
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from '@mui/material';

import { SelectChangeEvent } from '@mui/material/Select';

const NewUserShopForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    phone: '',
    admin: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
    try {
      // const res = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
      // if (res) {
      //   console.log('User created:', res.user);
      // }
      // else {
      //   alert('Error creating user');
      // }
      // await storeUserInfo(formData.email, formData);
    }
    catch (error) {
      alert(error);
    }
    
    // Here you would typically send the data to your backend
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          New User & Shop Registration
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="First Name"
              name="first_name"
              type="first_name"
              value={formData.first_name}
              onChange={handleChange}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Last Name"
              name="last_name"
              type="last_name"
              value={formData.last_name}
              onChange={handleChange}
              required
            />
          </Box>
          <Box mb={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Admin</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.admin}
                label="Age"
                name="admin"
                onChange={handleChange}
              >
                <MenuItem value={'Shmuel'}>Shmuel</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Button variant="contained" color="primary" type="submit">
            Submit
          </Button>
          
        </form>
      </Paper>
    </Container>
  );
};

export default NewUserShopForm;