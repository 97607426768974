import React, { memo } from 'react';
import { Feature, Point } from 'geojson';
import { NailSalonFeatureProps } from '../nail_salons';
import { useNavigate } from 'react-router-dom';
import MapImage from '../../../components/iconrow/icons8-map-48.png';
import SchduleImage from './icons8-schedule-48.png';
import { Typography, ThemeProvider, createTheme } from '@mui/material';
import { getPublicShopInfo } from '../../../resources/shopsDB/ShopsDB';

type InfowindowContentProps = {
  features: Feature<Point>[];
};

const theme = createTheme({
  typography: {
    fontFamily: 'BlinkMacSystemFont',
  },
});

export const InfoWindowContent = memo(({ features }: InfowindowContentProps) => {
  const navigate = useNavigate();

  if (features.length === 1) {

    const f = features[0];
    const props = f.properties! as NailSalonFeatureProps;

    const encodedAddress = encodeURIComponent(props.address);
    const mapUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;

    return (
      <div style={{
        width: '170px',
        height: '170px',
        borderRadius: '50%',
        overflow: 'hidden',
        position: 'relative',
        textAlign: 'center',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
        background: 'transparent',
        clipPath: 'circle(50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }} onClick={() => navigate(`/shop/${f.id}`)}>
        <img
          src={props.header_image}
          alt={props.name}
          style={{
            width: '170px',
            height: '170px',
            objectFit: 'cover',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            margin: 0,
            padding: 0,
            display: 'block',
          }}
        />
        <div style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          background: 'rgba(255, 255, 255, 0.8)',
          width: '100%',
          minHeight: '70px', // Set minimum height
          maxHeight: '85px', // Set maximum height
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '0 0 85px 85px',
          boxShadow: 'inset 0 -2px 8px rgba(0, 0, 0, 0.2)',
          transform: 'translateY(2px)',
          margin: 0,
          padding: '10px 15px', // Adjusted padding
          boxSizing: 'border-box', // Include padding in element's total size
          overflow: 'hidden', // Add overflow hidden
        }}>
          <ThemeProvider theme={theme}>
            <Typography variant="h6" sx={{
              textTransform: 'uppercase',
              fontStyle: 'italic',
              textShadow: '1px 1px 5px rgba(0, 0, 0, 0.3)',
              fontSize: '16px', // Reduced font size
              margin: 0,
              padding: 0,
              lineHeight: 1.2,
              display: '-webkit-box',
              WebkitLineClamp: 2, // Limit to 2 lines
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              wordWrap: 'break-word',
              maxWidth: '140px', // Leave some space on sides
            }}>
              {props.name}
            </Typography>
          </ThemeProvider>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <h4>{features.length} features. Zoom in to explore.</h4>
        <ul>
          {features.slice(0, 5).map(feature => {
            const props = feature.properties! as NailSalonFeatureProps;
            return (
              <li key={feature.id}>
                <a onClick={() => navigate(`/shop/search?shopID=${feature.id}`)} target="_blank">
                  {props.name}
                </a>
              </li>
            );
          })}
          {features.length > 5 && (
            <li>and {features.length - 5} more.</li>
          )}
        </ul>
      </div>
    );
  }
});