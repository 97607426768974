import React, { useState, useEffect, useRef } from 'react';
import ResponsiveAppBar from '../../components/appbar/Appbar';
import ShopCard from './ShopCard';
import { fetchNextPage, ShopInfo } from '../../resources/shopsDB/ShopsDB';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

const HomePage: React.FC = () => {
  const [shops, setShops] = useState<ShopInfo[]>([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const observerRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent, shopInfo) => {
    if (!shopInfo.id) {
      return;
    }
    navigate(`/shop/${shopInfo.id}`);
  };

  // Infinite scroll: Observe the sentinel element
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && hasMore && !loading) {
          // Load the next page of shops
          void fetchNextPage(lastVisible, hasMore, loading, setLoading, setShops, setLastVisible, setHasMore);
        }
      },
      { threshold: 1.0 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [lastVisible, hasMore, loading]);

  if (loading && shops.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <ResponsiveAppBar />
      <Box 
       sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))', // Adjust 150px for minimum card width
        gridGap: '2px', // Minimal gap between cards
        width: '100%',
        // Ensure each grid item stretches to fill the grid cell
        '& > div': {
          height: '100%', // Make sure the div inside each grid cell takes full height
          display: 'flex', // Use flexbox for full coverage
        },
      }}
      >
        {shops.map((shop) => (
          <div 
            key={shop.name}
            // style={{
            //    maxWidth: '300px', // Maximum width for each card to prevent overly large cards
            // }}
          >
            <ShopCard shopInfo={shop} handleOnClick={handleClick} />
          </div>
        ))}
      </Box>


      <div
        ref={observerRef}
        style={{ height: 20, marginBottom: 10, background: 'transparent' }}
      >
        {/* Sentinel element */}
        {loading && <p>Loading more shops...</p>}
      </div>
    </div>
  );
};

export default HomePage;