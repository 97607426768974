import React, { useEffect } from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CardActionArea from '@mui/material/CardActionArea';
import { UserInfo } from '../../../resources/usersDB/UsersDB';
import { fetchBucketImage } from '../../../components/auth/Bucketimages';
import fileuploadIcon from '../../components/auth/upload-image-icon-50.png'
import Box from '@mui/material/Box';



interface ShopCardProps {
    UserInfo: UserInfo;
    handleOnClick: any
}



const ProfileImage: React.FC<ShopCardProps> = ({ UserInfo, handleOnClick }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [imageUrl, setImageUrl] = React.useState('');

  useEffect(() => {
    fetchBucketImage(setImageUrl, UserInfo.photoURL);
  }, [[], UserInfo],); 

  useEffect(() => {
    if (imageUrl) {
      setIsLoading(false);
    }
  }, [imageUrl]);

  if (isLoading) {
    return <div></div>;
  }
  else {
    return (
      <Card 
      sx={{ 
        position: 'relative', // Use relative for positioning child elements
      }} 
      onClick={(event) => handleOnClick(event, UserInfo)}
    >
      <CardMedia 
        component="img" 
        sx={{ 
          position: 'relative', 
          // width: '100%',
          // maxWidth: '300px', // or any other width according to your design
          height: '200px', // set this according to aspect ratio or design needs
          width: '200px',
        }} 
        image={imageUrl} 
        alt={UserInfo.first_name} 
      />
      <Box 
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
          padding: 1,
          color: 'white', // Text color for contrast
        }}
      >
      </Box>
    </Card>
    );
  }
};

export default ProfileImage;
