import React, { useEffect } from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CardActionArea from '@mui/material/CardActionArea';
import { ShopInfo } from '../../resources/shopsDB/ShopsDB';
import { fetchBucketImage } from '../../components/auth/Bucketimages';
import fileuploadIcon from '../../components/auth/upload-image-icon-50.png'
import Box from '@mui/material/Box';



interface ShopCardProps {
    shopInfo: ShopInfo;
    handleOnClick: any
}



const ShopCard: React.FC<ShopCardProps> = ({ shopInfo, handleOnClick }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [imageUrl, setImageUrl] = React.useState('');

  useEffect(() => {
    fetchBucketImage(setImageUrl, shopInfo.header_image);
  }, [[], shopInfo],); 

  useEffect(() => {
    if (imageUrl) {
      setIsLoading(false);
    }
  }, [imageUrl]);

  if (isLoading) {
    return <div></div>;
  }
  else {
    return (
      <Card 
      sx={{ 
        position: 'relative', // Use relative for positioning child elements
      }} 
      onClick={(event) => handleOnClick(event, shopInfo)}
    >
      <CardMedia 
        component="img" 
        sx={{ 
          position: 'relative', 
          // width: '100%',
          // maxWidth: '300px', // or any other width according to your design
          height: '200px', // set this according to aspect ratio or design needs
          width: '200px',
        }} 
        image={imageUrl} 
        alt={shopInfo.name} 
      />
      <Box 
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
          padding: 1,
          color: 'white', // Text color for contrast
        }}
      >
        <CardContent 
          sx={{ 
            padding: '10px', 
            position: 'relative', // Keep relative for context
            height: '100%', // Ensure CardContent takes up full height of its container
          }}
        >
          <Typography
            sx={{
              fontSize: 'clamp(12px, 5vw, 16px)', // Responsive font size
              fontFamily: "'Roboto Slab', serif",
              position: 'absolute', // Position absolutely within CardContent
              bottom: '0', // Align to the bottom of CardContent
              left: '0', // Ensure text starts from the left
              right: '0', // Ensure text spans the full width
              padding: '8px 16px', // Add padding inside Typography for spacing
              margin: '0', // Reset margin to ensure no unexpected spacing
              // Text overflow handling:
              whiteSpace: 'nowrap', // Prevents wrapping
              overflow: 'hidden', // Hides content that overflows
              textOverflow: 'ellipsis', // Adds ellipsis for overflow
            }}
          >
            {shopInfo.name}
          </Typography>
        </CardContent>
      </Box>
    </Card>
    );
  }
};

export default ShopCard;
