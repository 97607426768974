import React, { createContext, useContext, useState, useEffect } from 'react';
import { AuthContextType, UserType } from './types'; // Define types for user and context
import { getAuth, onAuthStateChanged, setPersistence, browserLocalPersistence, browserSessionPersistence, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut,signInWithPopup, GoogleAuthProvider, signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';

import { signInWithRedirect, getRedirectResult } from 'firebase/auth';
import { app, auth } from './firebase'; // Import Firebase app
import { storeUserInfo, getUserInfo} from '../../resources/usersDB/UsersDB'; // Import function to store user info in Firestore




await setPersistence(auth, browserLocalPersistence)

export const signIn = async (email: string, password: string) => {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    return res
  } catch (error) {
    // Handle errors appropriately
    
  }
};

// Initialize Firebase Auth provider
const googleProvider = new GoogleAuthProvider();

// whenever a user interacts with the provider, we force them to select an account
googleProvider.setCustomParameters({   
    prompt : "select_account "
});

export const signInWithGooglePopup = async () => {
  const res = await signInWithPopup(auth, googleProvider)
  var userInfo = await getUserInfo();
  userInfo = {...userInfo, photoURL: res.user.photoURL};
  // await storeUserInfo(res.user.uid, userInfo);

  return res 
};

export const signInWithGoogleRedirect = async () => {
  try{
  await signInWithRedirect(auth, googleProvider);
  const res = await getRedirectResult(auth);
  const userInfo = await getUserInfo();
  // await storeUserInfo(res.user.email, userInfo);

  return res
  } catch (error) {
    console.log(error)
  }
};

export const handleRedirectResult = async () => {
  try {
    const result = await getRedirectResult(auth);
    if (result) {
      const user = result.user;
      const userInfo = await getUserInfo(); // Ensure this function exists and returns what you need
      // await storeUserInfo(user.email, userInfo); // Uncomment if this function is implemented
      console.log("User signed in:", user);
      return result;
    }
  } catch (error) {
    console.error("Error handling redirect result:", error);
  }
};

export const wrapperSignUp = async (firstName: string, lastName: string, phone_number: string, email: string, password: string) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const userInfo = {"first_name": firstName, "last_name": lastName, "phone_number": phone_number, "email": res.user.email, "address": "", "shopOwned": ""};
    // await storeUserInfo(userInfo.uid, userInfo);
    return res
  } catch (error) {
    console.log(error)
    // Handle errors appropriately
  }
};

export const wrapperSignOut = async () => {
  const res = await signOut(auth);
  return res
};


