import React, { useEffect, useState, useRef } from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list';
import rrulePlugin from '@fullcalendar/rrule'
import interactionPlugin from '@fullcalendar/interaction'
import { getAppointmentInfo, deleteAppointment, bookAppointment } from '../../resources/appointmentsDB/AppointmentsDB';
import { getPublicShopInfo, getShopWeeklySchedule, ShopInfo } from '../../resources/shopsDB/ShopsDB';
import { Service } from '../../resources/shopsDB/ShopsDB';
import { auth } from '../auth/firebase';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';


interface ShopCalendarProps {
  shopInfo: ShopInfo
  shopId: string
  selectedService: Service
}

type Event = Object

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ShopCalendar: React.FC<ShopCalendarProps> = ({shopInfo, shopId, selectedService}) => {
  const slotGranularity = 10
  const calendarRef = useRef(null);
  const initLoaded = useRef(true);
  const [isLoading, setIsLoading] = useState(true);
  const [focusDate, setFocusDate] = useState(null);
  // const [shopId, setShopId] = useState(shopId);
  const [currentEvents, setCurrentEvents] = useState<Event[]>([]);
  const [userEvents, setUserEvents] = useState<any>([]);
  const [selectedEvents, setSelectedEvents] = useState<any>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [confirmWindowOpen, setConfirmWindowOpen] = useState(false);
  const [confirmWindowMessage, setConfirmWindowMessage] = useState('');
  const [selectedEvent, setSelectedEvent] = useState<any | null>(null);
  
  const [requestFieldValue, setrequestFieldValue] = useState('');

  



  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    setCurrentEvents([...userEvents,...selectedEvents])
  }, [userEvents,selectedEvents]);

  useEffect(() => {
    if (focusDate && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(focusDate);
      setFocusDate(null); // Reset focus date after navigating
    }
  }, [focusDate]);

  // useEffect(() => {
  //   if (initLoaded.current) {
  //     initLoaded.current = false;
  //     return;
  //   }
  //   setSnackbarOpen(true);
  // }, [snackbarMessage]);


  const handleRequestFieldChange = (event) => {
    setrequestFieldValue(event.target.value);
  };


  const fetchEvents = async () => {
    await setIsLoading(true);
    // const shopHours = await getShopAvailableHours(shopId)
    const publicAppointments = await getAppointmentInfo(shopId)
    var events_list: Event[] = await getShopWeeklySchedule(shopId)
    events_list.forEach((event) => {
      event['display'] = 'background'      
    })
    // events_list.push(my_event)
    events_list.push(...publicAppointments)
    await setCurrentEvents(events_list)
    await setIsLoading(false);
  }
  

  // Adds color coding to the day cells based on the availability of events
  const dayCellDidMount = (arg) => {
    const dateNumber = arg.el.querySelector('.fc-daygrid-day-number');
    if (dateNumber) {
      dateNumber.style.pointerEvents = 'none';
      dateNumber.style.cursor = 'default';
    }
    else {
      return
    }
    const { date, el } = arg;
    // const dayEvents = currentEvents.filter(event => 
    //   event.start.toDateString() === date.toDateString()
    // );

    if (currentEvents.length > 0) {
      const allAvailable = currentEvents.every(event => (event as any).title! == 'available');
      const someAvailable = currentEvents.some(event => (event as any).title! == 'available');

      if (allAvailable) {
        el.classList.add('fc-day-all-available');
      } else if (someAvailable) {
        el.classList.add('fc-day-some-available');
      } else {
         el.classList.add('fc-day-none-available');
      }
    }
  };


  const checkEventsAtTime = (checkTime) => {
    if (selectedService == null){
      setSnackbarMessage('Please select a service')
      setSnackbarOpen(true)
      return true;
    }
    // Ensure the calendar is loaded
    const calendarApi = calendarRef.current?.getApi();
    if (!calendarApi) return [];

    // Get all events
    const allEvents = calendarApi.getEvents();

    // Filter events that overlap with the given time
    for (let i = 0; i < allEvents.length; i++){
      if (allEvents[i].display === 'background') {
        continue;
      }
      const eventStart = allEvents[i].start;
      const eventEnd = allEvents[i].end;

      for (let j = 0; j < (selectedService.time_length / slotGranularity); j++) {
        var iterativeTime = new Date(checkTime.getTime() + j * slotGranularity * 60000);
        if (iterativeTime.getTime() === eventStart.getTime()) {
          setSnackbarMessage('You need at least ' + selectedService.time_length + ' minutes for the selected appointment')
          setSnackbarOpen(true)
          return true;
        }
      }
    }
  };

  const checkAvailableEventsAtTime = (checkTime) => {
    // Ensure the calendar is loaded
    const calendarApi = calendarRef.current?.getApi();
    if (!calendarApi) return [];

    // Get all events
    const allEvents = calendarApi.getEvents();

    // Filter events that overlap with the given time
    const eventsAtTime = allEvents.filter(event => {
      const eventStart = event.start;
      const eventEnd = event.end;

      // Convert checkTime to a Date object if it's a string
      const timeToCheck = typeof checkTime === 'string' 
        ? new Date(checkTime) 
        : checkTime;

      // Check if the time is within any event's start and end
      return eventStart && eventEnd &&
             timeToCheck >= eventStart && 
             timeToCheck < eventEnd && event.display === 'background';
    });

    return eventsAtTime;
  };

  const checkEventinShopHours = (checkTime) => {
    // Ensure the calendar is loaded
    const calendarApi = calendarRef.current?.getApi();
    const currentEvent = calendarApi.getEvents();
    // let duringShopHours = false
    for (let i = 0; i < currentEvent.length; i++) {
      if (currentEvent[i].display === 'background') {
        let innerLoop = true
        for (let j = 0; j < (selectedService.time_length / slotGranularity); j++) {
          var iterativeTime = new Date(checkTime.getTime() + j * slotGranularity * 60000);
          if (iterativeTime.getTime() < currentEvent[i].start.getTime() || iterativeTime.getTime() >= currentEvent[i].end.getTime()) {
            innerLoop = false            
          }
        }
        if (innerLoop) {
          return true
        }
      }
    }
    return false
  }

  /* handles how the calendar should behave when a date is selected
  if the view is month, change to day view
  if the hours are selected create an event and add it to the database
  */
  const handleDateSelect = async (selectInfo: any) => {
    if (selectInfo.view.type === 'dayGridMonth') {
      let calendarApi = selectInfo.view.calendar
      calendarApi.changeView('timeGridDay', selectInfo.startStr);
      return

    }
    if (auth.currentUser == null) {
      setSnackbarMessage('You must be logged in to book an appointment')
      setSnackbarOpen(true)
      return
    }
    if (selectedService == null) {
      setSnackbarMessage('Please select a service')
      setSnackbarOpen(true)
      return
    }

    if (checkAvailableEventsAtTime(selectInfo.date).length === 0) {
      return
    }

    if (!checkEventinShopHours(selectInfo.date)) {
      setSnackbarMessage('You need at least ' + selectedService.time_length + ' minutes for the selected appointment')
      setSnackbarOpen(true)
      return
    }

    if (checkEventsAtTime(selectInfo.date)) {
      return
    }

    
    
    

    // var startTime = selectInfo.date.toLocaleTimeString()

    var endTimeDate = GetEndAppointmentDate(selectInfo.date, selectedService.time_length)
    

    setConfirmWindowMessage('Book ' + selectedService.name + ' between \n' + selectInfo.date.toTimeString().split(' ')[0].slice(0,-3) + ' to ' + endTimeDate.toTimeString().split(' ')[0].slice(0,-3))
    
    // let confirmSlot = window.confirm('Would you like to book an appointment between \n' + selectInfo.date.toTimeString().split(' ')[0].slice(0,-3) + ' to ' + endTimeDate.toTimeString().split(' ')[0].slice(0,-3))

    let calendarApi = selectInfo.view.calendar

    calendarApi.unselect() // clear date selection
    setSelectedEvent(selectInfo)
    setConfirmWindowOpen(true)

    if (true) {
      return
    }
  }

  const GetEndAppointmentDate = (startTimeDate: Date, serviceTime) => {
    const minutesToAdd = parseInt(serviceTime, 10);
    const endDate = new Date(startTimeDate);
    endDate.setMinutes(startTimeDate.getMinutes() + minutesToAdd);
    return endDate
  }


  const confirmAppointment = async () => {
    setConfirmWindowOpen(false)
    const endTimeDate = GetEndAppointmentDate(selectedEvent.date, selectedService.time_length)


    // get difference in milliseconds
    var appointmentLength = (new Date(selectedEvent.endStr).getTime() - new Date(selectedEvent.startStr).getTime())
    // convert to hours
    appointmentLength = appointmentLength / 1000 / 60 / 60
    // convert to number of 30 minute blocks
    appointmentLength = appointmentLength * 2

    const client_id = auth.currentUser.uid

    await bookAppointment({
        title: shopInfo.name,
        start: selectedEvent.date,
        end: endTimeDate,
        shop_id: shopId,
        client_id: client_id,
      },
      {
        service_name: selectedService.name,
        client_name: auth.currentUser.displayName,
        client_phone: auth.currentUser.phoneNumber ? auth.currentUser.phoneNumber : '',
        client_requests: requestFieldValue,
      }
    )
    await fetchEvents()
    setFocusDate(selectedEvent.start);
  }


  const handleEventClick = async (clickInfo: any) => {
    let calendarApi = clickInfo.view.calendar
    if (calendarApi.view.type === 'dayGridMonth') {
      return
    }
    if (clickInfo.event.backgroundColor === 'gray' || clickInfo.event.display === 'background') {
      return
    }

    const removeEvent = window.confirm(`Are you sure you want to delete the event ${clickInfo.event.startStr.split('T')[1].split('+')[0].substr(0, 5)}-${clickInfo.event.endStr.split('T')[1].split('+')[0].substr(0, 5)}?`)
    if (removeEvent) {
      await deleteAppointment(clickInfo.event.id)
      await fetchEvents()
      setFocusDate(clickInfo.event.start);
    }
    
  }



  const handleEvents = (events: any) => {
    // setCurrentEvents(events)
  }


  const renderEventContent = (eventInfo: any) => {
    let calendarApi = eventInfo.view.calendar
    if (calendarApi.view.type === 'dayGridMonth') {
      return (
        null 
      )
    }
    else{
    return (
      <>
        <b>{eventInfo.timeText}</b> 
        <br />
        <i>{eventInfo.event.title}</i>
        <br />
        <i>{eventInfo.event.extendedProps.service_name}</i>
        {/* <i> {eventInfo.event.shop_id}</i> */}
      </>
    )
    }
  }

  

  if (isLoading) {
    return <div>Loading events...</div>;
  }
    return (
      <div className='lilac-calendar'>
        <div className='demo-app-main'>
        <React.Fragment>
            <Dialog
              open={confirmWindowOpen}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => { setConfirmWindowOpen(false) }}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"Book an Appointment"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                {confirmWindowMessage}
                </DialogContentText>
                <TextField
                  autoFocus
                  fullWidth 
                  margin="dense"
                  id="requests"
                  name="requests"
                  value={requestFieldValue}
                  onChange={handleRequestFieldChange}
                  label="הערות"
                  variant="outlined"
                  multiline
                  minRows={2}
                />
              </DialogContent>
              
              <DialogActions>
                <Button onClick={() => { setConfirmWindowOpen(false) }}>Cancel</Button>
                <Button onClick={() => { confirmAppointment() }}>Agree</Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={snackbarOpen}
            autoHideDuration={1500}
            onClose={() => { setSnackbarOpen(false) }}
            // message={snackbarMessage}
            // key={vertical + horizontal}
          >
            <Alert severity="warning">{snackbarMessage}</Alert>
          </Snackbar>
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, rrulePlugin]}
            titleFormat={{ year: 'numeric', month: 'short', day: 'numeric' }}
            headerToolbar={{
              left: 'prev,next,today',
              center: 'title',
              right: 'dayGridMonth,timeGridDay',
            }}
            initialView='timeGridDay'
            eventTimeFormat={{
              hour: 'numeric',
              minute: '2-digit',
              meridiem: true
            }}
            slotLabelFormat={{
              hour: 'numeric',
              minute: '2-digit',
            }}
            editable={false}
            selectable={false}
            // selectAllow={handleSelectAllow} // Allow selection based on custom logic
            selectMirror={true}
            dayMaxEvents={true}
            events={currentEvents}
            slotDuration={{ minutes: slotGranularity }}
            // initialEvents={currentEvents} // alternatively, use the `events` setting to fetch from a feed
            dateClick={handleDateSelect}
            // select={handleDateSelect}

            eventContent={renderEventContent} // custom render function
            dayMaxEventRows={0} // This prevents the "+X more" link from appearing
            eventClick={handleEventClick}
            // eventsSet={handleEvents} // called after events are initialized/added/changed/removed
            slotMinTime="08:00:00" // Show only from 8:00 AM
            slotMaxTime="22:00:00" // Show only until 10:00 PM
            slotLabelInterval={{ minutes: slotGranularity }} // Show labels for every hour
            allDaySlot={false}
            navLinks={true}
            selectLongPressDelay={0}
            height="auto"
            
            aspectRatio={1.35}
            dayCellDidMount={dayCellDidMount}
            // eventDidMount={handleEventDidMount}
            eventStartEditable={false}
            eventResizableFromStart={false}
            eventDurationEditable={false}
            // you can update a remote database when these fire:
            // eventAdd={function(){}}
            //eventChange={function(){}}
            // eventRemove={function(){}}
            
          />
        </div>
      </div>
    )

}


  


  

export default ShopCalendar;