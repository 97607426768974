import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosResponse } from "axios";
import { auth } from '../../components/auth/firebase';

// Set the default configuration for Axios
axios.defaults.withCredentials = true;



const LandingPage: React.FC = () => {
    const navigate = useNavigate();

    const checkLoggedInCookie = async () => {
      navigate("/map");
      // try{
      //   if (auth.currentUser) {
      //     navigate("/home");
      //   } 
      //   else {
      //     navigate("/login");
      //   }}
      // catch (err) {
      //   navigate("/login");
      // }
      
    }
    
    useEffect(() => {
      checkLoggedInCookie();
      }, []);

    

    return null;

};

export default LandingPage;
