import * as React from 'react';
import { handleFileUpload, deleteFileByDownloadUrl } from '../../components/auth/firebase';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Service, addShopService } from '../../resources/shopsDB/ShopsDB';
import OvalCard from './OvalCard';
import OvalCardaddService from './OvalCardaddService';
import { ShopInfo } from '../../resources/shopsDB/ShopsDB';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { de } from 'date-fns/locale';



  interface ShopInfoComponentProps {
    shopInfo: ShopInfo;
    selectedService: Service | null;
    onClick;
    addService: boolean
  }


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const ShopServices: React.FC<ShopInfoComponentProps> = ({shopInfo, onClick, selectedService, addService=false}) => {

  const [open, setOpen] = React.useState(false);
  const [timeLength, setTimeLength] = React.useState(10);
  const [imageUrl, setImageUrl] = React.useState("");
  const handleChange = (event: SelectChangeEvent) => {
    setTimeLength(Number(event.target.value));
  };

  const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

  if (shopInfo === null) {
    return (
      <div>
      </div>
    );
  }
  return (
    <Grid container spacing={2} flexDirection={'row'} justifyContent={'center'}>
      {/* {[lightTheme, darkTheme].map((theme, index) => ( */}
        <Grid item xs={12} >
          {/* <ThemeProvider theme={theme}> */}
            <Box
              sx={{
                paddingLeft: 2,
                paddingRight: 2,
                // borderRadius: 2,
                // bgcolor: 'background.default',
                background: "#fff8f5",
                display: 'grid',
                gridTemplateColumns: { md: '1fr 1fr' },
                gap: 2,
              }}
            >
              <div>
              {shopInfo.shop_services.map((service, idx) => (
                <OvalCard key={idx} service={service} highLight={selectedService && selectedService.name === service.name} onClick={()=>{onClick(service)}}/>
              ))}
              {addService ? 
                <div>
                <OvalCardaddService key={99999} title={''} imageUrl={''} price={''} duration={''} onClick={handleClickOpen}/>

                <React.Fragment>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      component: 'form',
                      onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries((formData as any).entries());
                        const shopService = {description: formJson.description ? formJson.description : "", image: imageUrl, name: formJson.name, price: formJson.price, time_length: timeLength} as Service;
                        try{
                          await addShopService(shopService)
                        }catch(e){
                          console.log(e)
                          await deleteFileByDownloadUrl(imageUrl);
                        }
                        window.location.reload();
                      },
                    }}
                  >
                    <DialogTitle>הוסף שירות</DialogTitle>
                    <DialogContent>
                      <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="name"
                        label="שם השירות"
                        type="text"
                        fullWidth
                        variant="standard"
                      />
                      <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="price"
                        name="price"
                        label="מחיר"
                        type="number"
                        fullWidth
                        variant="standard"
                      />
                      <FormControl variant="standard" sx={{ marginTop: 2, minWidth: 180 }}>
                        <InputLabel id="demo-simple-select-standard-label">משך השירות בדקות</InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={timeLength.toString()}
                          onChange={handleChange}
                          label="Time length in minutes"
                        >
                          {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120].map((value) => (
                            <MenuItem key={value} value={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="description"
                        name="description"
                        label="תיאור"
                        type="text"
                        fullWidth
                        variant="standard"
                      />
                      <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                      >
                        העלה קבצים
                        <VisuallyHiddenInput
                          type="file"
                          onChange={(event) => handleFileUpload(event.target.files, setImageUrl)}
                          accept="image/*"
                        />
                      </Button>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => {
                        deleteFileByDownloadUrl(imageUrl);
                        handleClose()}}>ביטול</Button>
                      <Button type="submit">הוסף שירות</Button>
                    </DialogActions>
                  </Dialog>
                </React.Fragment>
                </div>

                :
                null
              }
              </div>
            </Box>
        </Grid>

        
      
    </Grid>

    
  );
}

export default ShopServices;