import React, { useState } from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import { Link } from 'react-router-dom';



const ImageMenuButton = ({phoneNumber}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCall = () => {
    window.open(`tel:${phoneNumber}`);
    handleClose();
  }

  const handleWhatsapp = () => {
    window.open(`https://wa.me/${phoneNumber}`);
    handleClose();
  }

  return (
    <div>
      {/* Image as a button using IconButton */}
      {(phoneNumber) ?
      <IconButton onClick={handleClick} style={{ padding: 0 }} >
        <PhoneEnabledIcon
          style={{ width: '48px', height: '48px', cursor: 'pointer', fill: 'hsl(140, 71%, 44%)' }}
        />
      </IconButton> : 
        <IconButton style={{ padding: 0 }} >
        <PhoneEnabledIcon
          style={{ width: '48px', height: '48px', cursor: 'pointer', fill: '#605951' }}
        />
      </IconButton>}

      {/* MUI Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem component={Link} to={`tel:${phoneNumber}`}>        
          Call
        </MenuItem>
        <MenuItem onClick={handleWhatsapp}>Whatsapp</MenuItem>
      </Menu>
    </div>
  );
};

export default ImageMenuButton;
