import React, { useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import { ShopInfo } from '../../../resources/shopsDB/ShopsDB';
import { UserInfo } from '../../../resources/usersDB/UsersDB';

interface Place {
  address: string;
  coordinates: {
    _lat: number;
    _long: number;
  };
}

// Define a common interface for shared fields
interface CommonInfo {
  address: string;
}

// Update the Props interface
interface Props {
  Info;
  setInfo: (info: any) => void; // Allow setting either type
  required: boolean;
}

const GooglePlacesAutocomplete: React.FC<Props> = ({ Info, setInfo, required }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_AUTOCOMPLETE_API_KEY}&libraries=places`;
    script.async = true;
    script.defer = true;

    script.onload = initAutocomplete;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const initAutocomplete = () => {
    if (!inputRef.current) return;

    autocompleteRef.current = new google.maps.places.Autocomplete(inputRef.current, {
      fields: ['formatted_address', 'geometry'],
      types: ['address']
    });

    autocompleteRef.current.addListener('place_changed', handlePlaceSelect);
  };

  const handlePlaceSelect = () => {
    const place = autocompleteRef.current?.getPlace();
    
    if (!place?.geometry?.location) {
      setError('Please select an address from the dropdown');
      return;
    }

    const selectedPlace: Place = {
      address: place.formatted_address || '',
      coordinates: {
        _lat: place.geometry.location.lat(),
        _long: place.geometry.location.lng()
      }
    };

    setError('');

    // Type guard to determine if Info is ShopInfo or UserInfo
    if ('geometry' in Info) {
      // If Info is of type ShopInfo
      setInfo({ ...Info, address: selectedPlace.address, geometry: selectedPlace.coordinates });
    } else {
      // If Info is of type UserInfo
      setInfo({ ...Info, address: selectedPlace.address });
    }
  };

  return (
    <TextField
      label="כתובת"
      variant="outlined"
      fullWidth
      inputRef={inputRef}
      defaultValue={Info.address}
      error={!!error}
      helperText={error}
      multiline
      minRows={2}
      required={required}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default GooglePlacesAutocomplete;