import React, { createContext, useContext, useState, useEffect } from 'react';
import { AuthContextType, UserType } from './types'; // Define types for user and context
import { getAuth, onAuthStateChanged, setPersistence, browserLocalPersistence, browserSessionPersistence, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut,signInWithPopup, GoogleAuthProvider, signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';
import { TextField, Button, InputAdornment } from '@mui/material'

import { signInWithRedirect, getRedirectResult } from 'firebase/auth';
import { db } from './firebase'; // Import Firebase app
import { app, auth } from './firebase'; // Import Firebase app
import { storeUserInfo, getUserInfo} from '../../resources/usersDB/UsersDB'; // Import function to store user info in Firestore
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';


// Function to setup reCAPTCHA
function setupRecaptcha(phoneNumber: string, onVerificationSuccess: () => void) {
  const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
    'size': 'normal',
    'callback': () => {
      // reCAPTCHA solved, trigger verification
      onVerificationSuccess();
    },
    'expired-callback': () => {
      // Response expired. Ask user to solve reCAPTCHA again.
      console.log('reCAPTCHA expired');
    }
  });
  
  return recaptchaVerifier;
}

// Function to send verification code
async function sendVerificationCode(phoneNumber: string, recaptchaVerifier: RecaptchaVerifier) {
  try {
    const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
    // Store confirmationResult to use it later for code verification
    window.confirmationResult = confirmationResult;
    return true;
  } catch (error) {
    console.error('Error sending verification code:', error);
    return false;
  }
}

// Function to verify code
async function verifyCode(code: string) {
  try {
    const result = await window.confirmationResult.confirm(code);
    // User signed in successfully
    const user = result.user;
    
    // Store user data in Firestore
    await storeUserData(user);
    
    return { success: true, user };
  } catch (error) {
    console.error('Error verifying code:', error);
    return { success: false, error };
  }
}

// Function to store user data in Firestore
async function storeUserData(user: any) {
  try {
    const userRef = doc(db, 'users', user.uid);
    
    // Check if user document already exists
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      // Create new user document
      await setDoc(userRef, {
        phoneNumber: user.phoneNumber,
        verified: true,
        createdAt: new Date(),
        lastLogin: new Date()
      });
    } else {
      // Update existing user document
      await setDoc(userRef, {
        lastLogin: new Date()
      }, { merge: true });
    }
    
    return true;
  } catch (error) {
    console.error('Error storing user data:', error);
    return false;
  }
}

// Add type declaration for window.confirmationResult
declare global {
  interface Window {
    confirmationResult: any;
  }
}

// Example usage in a React component
export function PhoneAuth() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationSent, setVerificationSent] = useState(false);
  
  const handleSendCode = async () => {
    const recaptchaVerifier = setupRecaptcha(phoneNumber, async () => {
      // This is the success callback that replaces onSignInSubmit
      var phone_Number = phoneNumber
      if (phone_Number.length > 9) {
        phone_Number = (phone_Number.slice(1, 10));
      }
      phone_Number = `+972${phone_Number}`
      
      const success = await sendVerificationCode(phone_Number, recaptchaVerifier);
      if (success) {
        setVerificationSent(true);
      }
    });
    
    // Render the reCAPTCHA widget
    recaptchaVerifier.render();
  };
  
  const handleVerifyCode = async () => {
    const { success, user } = await verifyCode(verificationCode);
    if (success) {
      console.log('Phone verification successful!', user);
    }
  };
  
  return (
    <div>
      {!verificationSent ? (
        <>
          {/* <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter phone number"
          /> */}
          <TextField
                  autoFocus
                  fullWidth
                  margin="dense"
                  label="טלפון"
                  variant="outlined"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Phone Number"
                  InputProps={{
                      startAdornment: <InputAdornment position="start">+972</InputAdornment>,
                  }}
                  inputProps={{
                  maxLength: 10,
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  }}
                  required
                />
          <div id="recaptcha-container"></div>
          <button onClick={handleSendCode}>Send Verification Code</button>
        </>
      ) : (
        <>
          <input
            type="text"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            placeholder="Enter verification code"
          />
          <button onClick={handleVerifyCode}>Verify Code</button>
        </>
      )}
    </div>
  );
}
  
  export default PhoneAuth;