import { db } from "../../components/auth/firebase";
import { doc, 
    setDoc, 
    addDoc,
    getDoc, 
    getDocs, 
    collection, 
    updateDoc,  
    orderBy, 
    startAfter, 
    query,
    limit } from 'firebase/firestore';
import { auth } from "../../components/auth/firebase";
import { getUserInfo, updateUserInfo } from "../usersDB/UsersDB";
import { getAppointmentInfo } from "../appointmentsDB/AppointmentsDB";
import { ex } from "@fullcalendar/core/internal-common";


// Number of shops to fetch per page
const PAGE_SIZE = 10;


export interface ShopInfo {
    address: string;
    email: string;
    facebook: string;
    geometry: {_lat: number; _long: number,};
    header_image: string;
    instagram: string;
    name: string;
    phone: string;
    shop_services: Service[] | null;
    shopweeklyschedule: [];
}

export interface ShopSubData    {
    appointments: [];
    shopOwner: string;

}

export interface Service {
    description: string;
    image: string;
    name: string;
    price: number;
    shop_id: string;
    time_length: number;
}





export async function storeShopWeeklySchedule(weeklySchedule: object []): Promise<void> {
    try {
        const userData = await getUserInfo()
        await updateDoc(doc(db, "shops", userData.shopOwned), {
            shopweeklyschedule: JSON.stringify(weeklySchedule)
        });
        console.log("Shop Hours updated successfully!");
    } catch (error) {
        console.error("Error updating shop hours: ", error);
    }
}

export async function addShopService(service: Service): Promise<void> {
    try {
        const userData = await getUserInfo()
        service.shop_id = userData.shopOwned
        const docSnap = await getDoc(doc(db, "shops", userData.shopOwned)); 
        let ShopInfo = docSnap.data()
        ShopInfo.id = userData.shopOwned
        ShopInfo.shop_services.push(JSON.stringify(service))
        await updateShopInfo(ShopInfo as ShopInfo)
        console.log("Service successfully added!");
    } catch (error) {
        console.error("Error adding service: ", error);
    }
}

export async function removeShopService(service: Service): Promise<void> {
    try {
        const userData = await getUserInfo()
        const docSnap = await getDoc(doc(db, "shops", userData.shopOwned)); 
        let ShopInfo = docSnap.data()
        ShopInfo.id = userData.shopOwned
        let index = ShopInfo.shop_services.indexOf(JSON.stringify(service))
        ShopInfo.shop_services.splice(index, 1)
        updateShopInfo(ShopInfo as ShopInfo)
        console.log("Service successfully removed!");
    } catch (error) {
        console.error("Error removing service: ", error);
    }
}

export async function getShopServicesCollection(ShopId: string): Promise<Service[] | null> {
    try {
        const subcollectionRef = collection(db, 'shops', ShopId, 'shop_services');
        const subcollectionSnapshot = await getDocs(subcollectionRef);
        const subcollectionData = subcollectionSnapshot.docs.map(doc => ({
        ...doc.data()
        }));

        if (subcollectionData.length > 0){
            const shopSubData: Service[] = subcollectionData.map(doc => ({
                description: doc.description,
                image: doc.image,
                name: doc.name,
                price: doc.price,
                shop_id: doc.shop_id,
                time_length: doc.time_length
            }))
            return shopSubData;
        }
        console.error("No data found");
        return [];
    } catch (error) {
        console.error("Error getting shop services collection information: ", error);
        return null;
    }
}


// Function to get user info
export async function getPublicShopInfo(ShopId: string | null): Promise<ShopInfo | null> {
    try {
        // if (auth.currentUser) {
            var docSnap
            if (ShopId !== null) {
                docSnap = await getDoc(doc(db, "shops", ShopId));                
            }
            else {
                const userData = await getUserInfo()
                docSnap = await getDoc(doc(db, "shops", userData.shopOwned));

            }
            return await {
                address: docSnap.data().address,
                email: docSnap.data().email,
                facebook: docSnap.data().facebook,
                geometry: docSnap.data().geometry,
                header_image: docSnap.data().header_image,
                instagram: docSnap.data().instagram,
                name: docSnap.data().name,
                phone: docSnap.data().phone,
                shop_services: docSnap.data().shop_services ? docSnap.data().shop_services.map(doc => (JSON.parse(doc))) : [],
                shopweeklyschedule: docSnap.data().shopweeklyschedule ? JSON.parse(docSnap.data().shopweeklyschedule) : "{}",
            }
        // }
        // else {
        //     alert('Please login or sign up')
        //     return null};
    } catch (error) {
        console.error("Error getting shop information: ", error);
        return null;
    }
}

// Fetch next page of shops
export const fetchNextPage = async (lastVisible, hasMore: boolean, loading: boolean, setLoading, setShops, setLastVisible, setHasMore) => {
    if (!hasMore || loading) return;

    setLoading(true);
    try {
      // Create a query starting after the last visible document
      const next = query(
        collection(db, 'shops'),
        orderBy('name'), // Order by shop name, adjust as needed
        startAfter(lastVisible),
        limit(PAGE_SIZE)
      );

      const documentSnapshots = await getDocs(next);
      
      // Add new shops to existing list
      const newShops = documentSnapshots.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setShops(prevShops => [...prevShops, ...newShops]);
      
      // Update last visible document
      const lastDoc = documentSnapshots.docs[documentSnapshots.docs.length - 1];
      setLastVisible(lastDoc);
      
      // Check if there are more shops
      setHasMore(documentSnapshots.docs.length === PAGE_SIZE);
    } catch (error) {
      console.error("Error fetching next page of shops:", error);
    }
    setLoading(false);
  };


  export async function createShop(ShopInfo): Promise<string | null> {
    try {
        ShopInfo.shop_services = ShopInfo.shop_services ? ShopInfo.shop_services : [];
        ShopInfo.shopweeklyschedule = ShopInfo.shopweeklyschedule ? ShopInfo.shopweeklyschedule : "[]";

        // await setDoc(doc(db, "shops"), ShopInfo);
        const docRef = await addDoc(collection(db, "shops"), ShopInfo);
        var userInfo = await getUserInfo()
        userInfo.shopOwned = docRef.id
        await updateUserInfo(userInfo)
        console.log("Shop successfully created!");

        let segments = window.location.href.split('/');
        // Remove the last two segments
        segments = segments.slice(0, -2);
        // Join the remaining segments back into a string
        let newPath = segments.join('/');
        window.location.href = newPath += `/shop/${docRef.id}`;
        return docRef.id
    } catch (error) {
        console.error("Error creating shop: ", error);
        return null
    }
}


export async function updateShopInfo(ShopInfo: ShopInfo): Promise<string | null> {
    try {
        const userData = await getUserInfo()
        var shopID = ''
        if (!userData.shopOwned) {
            shopID = await createShop(ShopInfo)
            return shopID
        }
        else {
            const docSnap = await getDoc(doc(db, "shops", userData.shopOwned));
            await updateDoc(docSnap.ref, ShopInfo as object);
            return userData.shopOwned
        }
    } catch (error) {
        console.error("Error updating shop information: ", error);
        return null
    }
}

export async function getShopWeeklySchedule(ShopId: string): Promise<[]> {
    try {
        const docSnap = await getDoc(doc(db, "shops", ShopId));
        return JSON.parse(docSnap.data().shopweeklyschedule);
    } catch (error) {
        console.error("Error getting shop information: ", error);
        return [];
    }
}



