import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "./firebase"; // Adjust the path to your Firebase configuration
import { TextField, Button, Container, Typography, Box, Stepper, Step, StepLabel } from "@mui/material";

// Extend Window interface to include recaptchaVerifier
declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier | undefined;
  }
}

const PhoneAuth: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [confirmationResult, setConfirmationResult] = useState<any>(null);
  const [recaptchaInitialized, setRecaptchaInitialized] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0); // Track the step (0 for phone number, 1 for OTP)
  const navigate = useNavigate();

  // Function to initialize reCAPTCHA
  const setupRecaptcha = () => {
    const recaptchaElement = document.getElementById("recaptcha-container");
    if (!recaptchaElement) {
      console.error("reCAPTCHA container element not found.");
      return;
    }

    if (!recaptchaInitialized) {
      const recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible", // Can be 'normal', 'compact', or 'invisible'
          callback: (response: string) => {
            console.log("reCAPTCHA solved:", response);
          },
          "expired-callback": () => {
            console.warn("reCAPTCHA expired. Please retry.");
          },
        },
      );

      window.recaptchaVerifier = recaptchaVerifier; // Assign to window
      recaptchaVerifier.render().then(() => {
        setRecaptchaInitialized(true);
      });
    }
  };

  // Initialize reCAPTCHA only after component mounts
  useEffect(() => {
    setupRecaptcha(); // Ensure reCAPTCHA is set up when component is ready
    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
      }
    };
  }, []);

  const sendOTP = async () => {
    try {
      const recaptchaVerifier = window.recaptchaVerifier;
      if (!recaptchaVerifier) {
        console.error("reCAPTCHA is not initialized.");
        return;
      }

      const confirmation = await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
      setConfirmationResult(confirmation);
      console.log("OTP sent successfully!");
      setStep(1); // Move to the next step (OTP input)
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const verifyCode = async () => {
    try {
      if (confirmationResult) {
        const result = await confirmationResult.confirm(verificationCode);
        console.log("User signed in successfully:", result.user);

        // Navigate to /map after successful login
        navigate("/map");
      }
    } catch (error) {
      console.error("Error verifying code:", error);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 3,
          backgroundColor: "#f9f9f9",
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        {/* <Typography variant="h4" gutterBottom>
          Phone Authentication
        </Typography> */}

        {/* Stepper */}
        <Stepper activeStep={step} alternativeLabel >
          <Step>
            <StepLabel sx={{ direction: 'rtl' }}>שליחת SMS</StepLabel>
          </Step>
          <Step>
            <StepLabel sx={{ direction: 'rtl' }}>אישור OTP</StepLabel>
          </Step>
        </Stepper>

        {/* Step 1: Enter Phone Number */}
        {step === 0 && (
          <>
            <TextField
              label="טלפון"
              variant="outlined"
              fullWidth
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              sx={{ marginBottom: 2,  marginTop: 2}}
            />

            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={sendOTP}
              sx={{ marginBottom: 2, direction: 'rtl',}}
            >
              שלח SMS
            </Button>
          </>
        )}

        {/* Step 2: Enter Verification Code */}
        {step === 1 && (
          <>
            <TextField
              label="Verification Code"
              variant="outlined"
              fullWidth
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              sx={{ marginBottom: 2, marginTop: 2 }}
            />

            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={verifyCode}
              sx={{ marginBottom: 2, direction: 'rtl' }}
            >
              אימות מספר
            </Button>
          </>
        )}

        {/* reCAPTCHA container */}
        <div id="recaptcha-container"></div>
      </Box>
    </Container>
  );
};

export default PhoneAuth;
