import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { wrapperSignOut } from '../../components/auth/Auth';
import { auth } from '../auth/firebase';
import { getUserInfo, UserInfo, } from '../../resources/usersDB/UsersDB';
import HomeIcon from '@mui/icons-material/Home';
import MapIcon from '@mui/icons-material/Map';
import SettingsIcon from '@mui/icons-material/Settings';
import { styled } from '@mui/material/styles';
import LogoIconImage from '../../lilac_logo_icon.png'
import { Drawer } from '@mui/material';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Divider from '@mui/material/Divider';

// Set the default configuration for Axios
axios.defaults.withCredentials = true;


// interface ResponsiveAppBarProps {
//   pageName: string;
// }
const CenteredToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'center',
});

const pages = ['Map', 'Settings'];

const ResponsiveAppBar: React.FC = () => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [userData, setUserData] = useState<UserInfo | null>(null);
  const [DrawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();


  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const openDrawer = () => {
    setDrawerOpen(true);
  }

  const closeDrawer = () => {
    setDrawerOpen(false);
  }

  const handleLogout = async () => { 
    try{
      const res = await wrapperSignOut();
    }
    catch (error) {
      console.log(error)
      // Handle errors appropriately
    }
      
    
    navigate("/");
  };

  const handleLogin = async () => { 
    navigate("/login");
  };

  const handleAccountPage = () => {
    navigate("/account");
  }

  const handleShopOwnerPage = () => {
    navigate("/shopowner");
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!auth.currentUser) {
        return;
      }
      const userInfo = await getUserInfo();
      setUserInfo(userInfo);
      // const userData = await getUserDataCollection();
      // setUserData(userData);
    }
    fetchData();
  }, [])



  const Logo = styled('img')(({ theme }) => ({
    height: '60%', // This will make the image height match the AppBar height
    width: 'auto', // Maintain aspect ratio
    objectFit: 'contain', // Ensure the entire image is visible without stretching
  }));

  const DrawerList = (
    <Box sx={{ width: 60,}} role="presentation" onClick={closeDrawer}>
      <List>
        <ListItem key={'Home'} onClick={() => {navigate('/home')}}>
                <HomeIcon />
        </ListItem>
        <ListItem key={'Map'} onClick={() => {navigate('/map')}}>
            <MapIcon />
        </ListItem>
        <ListItem key={'Settings'} onClick={() => {navigate('/settings')}}>
          <SettingsIcon />
        </ListItem>
      </List>
    </Box>
  );


  return (
      <AppBar position="static" sx={{ height: 64, background: 'linear-gradient(180deg,rgb(252, 176, 215) 1%,#fff8f5 100%)', boxShadow: 'none', }}>
          <Toolbar disableGutters sx={{display: 'flex', justifyContent: 'center', flexDirection: 'row'}}>
          <Drawer anchor={'left'} open={DrawerOpen} onClose={closeDrawer} PaperProps={{sx: { background: '#fff8f5'}}}>
            {DrawerList}
          </Drawer>
          <Box>
            <IconButton
              size="large"
              aria-label="page menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              // onClick={handleOpenNavMenu}
              onClick={openDrawer}
              color="inherit"
            >
              <MenuIcon/>
            </IconButton>
          </Box>
          <Logo src={LogoIconImage} alt="Lilac Logo"  sx={{flexGrow: 10}} onClick={() => {navigate('/home')}}/>
          <Box>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 1 }}>
                <Avatar alt="" src={userInfo ? userInfo.photoURL : "/static/images/avatar/2.jpg"} />
              </IconButton>
            </Tooltip>
            <Box>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              // transformOrigin={{
              //   vertical: 'top',
              //   horizontal: 'right',
              // }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              sx={{'& .MuiPaper-root': { background: '#fff8f5'}, }}
            >
                {userInfo ?
                  <MenuItem key='Account' onClick={handleAccountPage}>

                    <Typography textAlign="center">Account</Typography>
                  </MenuItem>
                  :
                  null
                }
                {userInfo && userInfo.shopOwned ?
                  <MenuItem key='Shop' onClick={handleShopOwnerPage}>
                    <Typography textAlign="center">Shop</Typography>
                  </MenuItem>
                  :
                  null
                }
                {userData && userData.role === 'admin' ?
                <MenuItem key='Admin' onClick={() => {navigate("/admin")}}>
                  <Typography textAlign="center">Admin</Typography>
                </MenuItem> :
                null}  
                {auth.currentUser ?
                <MenuItem key='Logout' onClick={handleLogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
                :
                <MenuItem key='Logout' onClick={handleLogin}>
                  <Typography textAlign="center">Login</Typography>
                </MenuItem>}

            </Menu>
            </Box>
            
          </Box>
          </Toolbar>
      </AppBar>
  );
}
export default ResponsiveAppBar;
