import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ResponsiveAppBar from '../../components/appbar/Appbar';
import { InstagramEmbed } from 'react-social-media-embed';
import { auth } from '../../components/auth/firebase';
import { Grid } from '@mui/material';
import IconRow from '../../components/iconrow/IconRow';
import ShopServices from '../../components/shopservices/ShopServices';
import { getPublicShopInfo, ShopInfo } from '../../resources/shopsDB/ShopsDB';
import '../../App.css';
import ShopCalendar from '../../components/shopCalendar/ShopCalendar';
import { Service } from '../../resources/shopsDB/ShopsDB';
import { useParams } from 'react-router-dom';

// Set the default configuration for Axios
// axios.defaults.withCredentials = true;




const ShopPage: React.FC = () => {

  
  const [shopInfo, setShopInfo] = useState<ShopInfo | null>(null);
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const shop_id = useParams().shop_id

  let navigate = useNavigate(); 

  const getShopInfo = async () => {
    // const shopsSnapshot = await getDoc(doc(db, "shops", shop_id));

    const shopData: ShopInfo = await getPublicShopInfo(shop_id)
    setShopInfo(shopData);
  }
  
  

  useEffect(() => {
    void getShopInfo();
  }, []);

  return (
    <div style={{ backgroundColor: "#fff8f5" }}>
      <ResponsiveAppBar />  
        <div>
          <IconRow shopInfo={shopInfo}/>
          <ShopServices shopInfo={shopInfo} selectedService={selectedService} onClick={(service)=>{setSelectedService(service)}} addService={false}/>
          <ShopCalendar shopInfo={shopInfo} shopId={shop_id} selectedService={selectedService}/>
        </div> 
    </div>
  );

};

export default ShopPage;