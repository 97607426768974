import { useState } from 'react';
import { Card, CardMedia, Box, Typography, Divider } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { GiTwoCoins } from 'react-icons/gi';

const OvalCard = ({ service, onClick, highLight }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleCardClick = () => {
    if (service.description){
      setIsFlipped(!isFlipped);
    }
      if (onClick) onClick();
  };

  const cardStyle = {
    perspective: '1000px',
    width: '100%',
    maxWidth: '400px',
    height: '80px',
    margin: '20px auto',
    position: 'relative',
  };

  const flipperStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
    transition: 'transform 0.6s',
    transformStyle: 'preserve-3d',
    transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)',
    cursor: 'pointer',
  };

  const faceStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
    borderRadius: '50px',
    backgroundColor: highLight ? '#eb9ffe' : '#f9e6fe',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  };

  return (
    <Box sx={cardStyle}>
      <Box sx={flipperStyle} onClick={handleCardClick}>
        <Card sx={{ ...faceStyle }}>
          <Box sx={{ display: 'flex', height: '100%' }}>
            <CardMedia
              component="img"
              sx={{ width: '80px', height: '80px', objectFit: 'cover' }}
              image={service.image}
              alt={service.name}
            />
            <Box sx={{ display: 'flex', width: 'calc(100% - 80px)', p: 1 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1 }}>
                <Typography variant="h6" component="div" align="center">
                  {service.name}
                </Typography>
              </Box>
              <Divider orientation="vertical" flexItem sx={{ mx: 1, borderStyle: 'dotted' }} />
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minWidth: '80px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <GiTwoCoins />
                  <Typography sx={{ fontSize: 16 }} variant="body2">{service.price}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <AccessTimeIcon sx={{ fontSize: 16, mr: 0.5 }} />
                  <Typography sx={{ fontSize: 16 }} variant="body2">{service.time_length}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Card>

        <Card sx={{ ...faceStyle, transform: 'rotateY(180deg)' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', p: 2 }}>
            <Typography 
              variant="body1" 
              align="center"
              sx={{
                fontSize: 'clamp(12px, 5vw, 16px)', // Responsive font size
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                whiteSpace: 'normal',
                maxWidth: '100%',
                maxHeight: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginBottom: 'auto', // Pushes text to the top if needed
              }}
            >
              {service.description}
            </Typography>
        </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default OvalCard;