import React, { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import ResponsiveAppBar from '../../../components/appbar/Appbar';


import { ShopInfo } from '../../../resources/shopsDB/ShopsDB';
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from 'react-router-dom';


import GooglePlacesAutocomplete from '../../ShopOwnerPage/ShopSettingsPage/GeocodeAutocomplete'
import { handleFileUpload, deleteFileByDownloadUrl } from '../../../components/auth/firebase';


import { getUserInfo, UserInfo, updateUserInfo } from '../../../resources/usersDB/UsersDB';
import Alert from '@mui/material/Alert'; // Import Alert component
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import ProfileImage from './ProfileImage';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';



import { createShop } from '../../../resources/shopsDB/ShopsDB';
import { Navigate } from 'react-router-dom';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });



const AccountDetailsPage: React.FC = () => {
    const mountedRef = useRef(false)
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [firstNameFieldValue, setfirstNameFieldValue] = useState('');
    const [lastNameFieldValue, setlastNameFieldValue] = useState('');
    const [telephoneFieldValue, settelephoneFieldValue] = useState('');
    const [emailFieldValue, setemailFieldValue] = useState('');
    const [facebookFieldValue, setfacebookFieldValue] = useState('');
    const [instagramFieldValue, setinstagramFieldValue] = useState('');
    const [headerimageFieldValue, setheaderimageFieldValue] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [alertMessage, setAlertMessage] = useState<string | null>(null); // State for alert message

    const [userAddress, setUserAddress] = useState({address: ''});


    const navigate = useNavigate();


    useEffect(() => {
        void getUserInfo().then(userInfo => {
            setfirstNameFieldValue(userInfo.first_name);
            setlastNameFieldValue(userInfo.last_name);
            setUserAddress({address: userInfo.address});
            setemailFieldValue(userInfo.email);
            settelephoneFieldValue(userInfo.phone_number.slice(4, userInfo.phone_number.length));
            setfacebookFieldValue('');
            setinstagramFieldValue('');
            setheaderimageFieldValue('');
            setUserInfo(userInfo)});
            
    }, []);

    useEffect(() => {
        if (mountedRef.current){
            updateUserPhoto({...userInfo, photoURL: imageUrl} as UserInfo);
        }
    }, [imageUrl]);

    async function updateUserPhoto(userInfo: UserInfo) {
        try {
            await updateUserInfo(userInfo);
            setUserInfo(userInfo);
            setAlertMessage(null); // Clear alert message if update is successful
        } catch (error) {
            console.error("Error updating user information: ", error);
            setAlertMessage("שגיאה בעת עדכון פרטים"); // Set alert message if update fails
        }
    }

    useEffect(() => {
        if (mountedRef.current){
            setLoading(false);
        }
        mountedRef.current = true;
    }, [userInfo]);

    useEffect(() => {
        setUserInfo({ ...userInfo, address: userAddress.address });
    }, [userAddress]);


    const handlefirstNameFieldChange = (event) => {
        setfirstNameFieldValue(event.target.value);
        setUserInfo({ ...userInfo, first_name: event.target.value });
    };

    const handlelastNameFieldChange = (event) => {
      setlastNameFieldValue(event.target.value);
      setUserInfo({ ...userInfo, last_name: event.target.value });
  };

    const handletelephoneFieldChange = (event) => {
        settelephoneFieldValue(event.target.value);
        setUserInfo({ ...userInfo, phone_number: event.target.value });
    };

    const handleEmailFieldChange = (event) => {
        setemailFieldValue(event.target.value);
        setUserInfo({ ...userInfo, email: event.target.value });
    };

    const handleInstagramFieldChange = (event) => {
        setinstagramFieldValue(event.target.value);
        setUserInfo({ ...userInfo, instagram: event.target.value });
    };

    // const handleHeaderImageFieldChange = (event) => {
    //     setheaderimageFieldValue(event.target.value);
    //     setUserInfo({ ...userInfo, header_image: event.target.value });
    // };
    const handleUpdate = () => {
        var varUserInfo = {
            first_name: firstNameFieldValue,
            last_name: lastNameFieldValue,
            address: userAddress.address,
            email: emailFieldValue,
            phone_number: telephoneFieldValue,
            instagram: instagramFieldValue,
        }
        if (telephoneFieldValue.length < 9) {
            setAlertMessage("נדרש לפחות 9 ספרות במספר הטלפון");
            setSnackbarOpen(true);
            return;
        }
        if (telephoneFieldValue.length == 10) {
            varUserInfo.phone_number = `+972${telephoneFieldValue.substr(1,)}`;
        }
        else {
            varUserInfo.phone_number = `+972${telephoneFieldValue}`;
        }

        // Proceed with updating user info
        updateUserInfo(varUserInfo as UserInfo);
        setAlertMessage(null); // Clear alert message if update is successful
    };


    const onClickCard = (event, UserInfo: UserInfo) => {
        // Create hidden file input
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*'; // Optional: limit to image files
        
        // Handle file selection
        fileInput.onchange = (e) => {
        const target = e.target as HTMLInputElement;
        const file = target.files?.[0];
        if (!file) return;
        

        handleFileUpload([file], setImageUrl);
        };

        // Trigger file picker
        fileInput.click();
    }
    
    if (loading) {
        return <div>Loading...</div>;
    }
    else {
        return (
    <div>
                {/* <ResponsiveAppBar /> */}
                <Box
                    display="flex"
                    justifyContent="space-around"
                    alignItems="center"
                    flexDirection="column"
                    flexWrap="wrap"
                    sx={{ gap: 2, padding: 2 }}
                >
                <Button onClick={() => {handleUpdate()}}
                    variant="contained"
                    sx={{
                    flexGrow: 6,
                    minWidth: 100,
                    textAlign: 'center',
                    background: 'linear-gradient(90deg, #b62a73 1%, #ffcdb3 100%)',
                    }}
                    >
                    עדכן פרטים
                </Button>
                <Snackbar
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                open={snackbarOpen}
                                autoHideDuration={1500}
                                onClose={() => { setSnackbarOpen(false) }}
                                // message={snackbarMessage}
                                // key={vertical + horizontal}
                              >
                        <Alert  severity="error" onClose={() => setAlertMessage(null)}>
                            {alertMessage}
                        </Alert>
                </Snackbar>
                <ProfileImage UserInfo={userInfo} handleOnClick={onClickCard} />

                <TextField
                    autoFocus
                    fullWidth 
                    margin="dense"
                    id="first_name"
                    name="first_name"
                    value={firstNameFieldValue}
                    onChange={handlefirstNameFieldChange}
                    label="שם פרטי"
                    variant="outlined"
                    required
                />
                <TextField
                    autoFocus
                    fullWidth 
                    margin="dense"
                    id="last_name"
                    name="last_name"
                    value={lastNameFieldValue}
                    onChange={handlelastNameFieldChange}
                    label="שם משפחה"
                    variant="outlined"
                    required
                />
                <TextField
                    label="מספר טלפון"
                    variant="outlined"
                    value={telephoneFieldValue}
                    onChange={handletelephoneFieldChange}
                    placeholder="Phone Number"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">+972</InputAdornment>,
                    }}
                    inputProps={{
                    maxLength: 10,
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    }}
                    required
                />
                <TextField
                    autoFocus
                    fullWidth 
                    margin="dense"
                    id="email"
                    name="email"
                    value={emailFieldValue}
                    onChange={handleEmailFieldChange}
                    label="מייל"
                    variant="outlined"
                    required
                />
                <GooglePlacesAutocomplete Info={userAddress} setInfo={setUserAddress} required={false}/>
                <TextField
                    autoFocus
                    fullWidth 
                    margin="dense"
                    id="instagram"
                    name="instagram"
                    value={instagramFieldValue}
                    onChange={handleInstagramFieldChange}
                    label="Instagram Handle"
                    variant="outlined"
                />
                {userInfo.shopOwned ? null :
                <Button onClick={() => {navigate('/shopowner/settings')}}
                    variant="contained"
                    sx={{
                    flexGrow: 6,
                    minWidth: 300,
                    textAlign: 'center',
                    background: 'linear-gradient(90deg, #b62a73 1%, #ffcdb3 100%)',
                    }}
                    >
                    פתח חנות
                </Button>
                }
                </Box>
                
            </div>
  );
}
}

export default AccountDetailsPage;