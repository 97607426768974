import { db } from "../../components/auth/firebase";
import { collection, query, where, doc, setDoc, getDoc, getDocs, deleteDoc, serverTimestamp, collectionGroup, DocumentReference } from 'firebase/firestore';

import { auth } from "../../components/auth/firebase";
import { v4 as uuidv4 } from 'uuid';
import { getUserInfo } from "../usersDB/UsersDB";


export interface Appointment {
    id: string,
    client_id: string,
    shop_id: string,
    title: string,
    start: Date,
    end: Date,
    allDay: boolean,
    status: string,
    backgroundColor?: string,
    // -------- Private Data --------
    client_name?: string,
    client_phone?: string,
    client_requests?: string,
    service_name?: string,
}

export interface PrivateData {
    service_name: string;
    client_name: string;
    client_phone: string;
    client_requests: string;
}

// Function to store user info
export async function createOwnerBlockHours(appointmentInfo: object, privateData: PrivateData): Promise<void> {
    try {
        // const appointmentId = uuidv4()
        appointmentInfo = {...appointmentInfo, createdAt: serverTimestamp()}

        const appointmentRef = doc(collection(db, 'appointments'));

        await setDoc(appointmentRef, {
            ...appointmentInfo,
        });

        // Reference to the privateData subcollection
        const privateDataCollectionRef = doc(collection(appointmentRef, 'privateData'));

        await setDoc(privateDataCollectionRef, {
            service_name: privateData.service_name,
            client_name: privateData.client_name,
            client_phone: privateData.client_phone,
            client_requests: privateData.client_requests,
            createdAt: serverTimestamp(),
            // Add any additional metadata
          })

        
        console.log("Appointment successfully stored!");
    } catch (error) {
        console.error("Error storing appointment: ", error);
    }
    }

export async function updateAppointmentStatus(appointmentId: string, status: string): Promise<void> {}

export async function bookAppointment(appointmentInfo: object, privateData: PrivateData): Promise<void> {
    try {
        // const appointmentId = uuidv4()
        appointmentInfo = {...appointmentInfo, createdAt: serverTimestamp()}

        const appointmentRef = doc(collection(db, 'appointments'));

        await setDoc(appointmentRef, {
            ...appointmentInfo,
        });

        // Reference to the privateData subcollection
        const privateDataCollectionRef = doc(collection(appointmentRef, 'privateData'));

        await setDoc(privateDataCollectionRef, {
            service_name: privateData.service_name,
            client_name: privateData.client_name,
            client_phone: privateData.client_phone,
            client_requests: privateData.client_requests,
            createdAt: serverTimestamp(),
            // Add any additional metadata
          })


        console.log("Appointment successfully stored!");
    } catch (error) {
        console.error("Error storing appointment: ", error);
    }
}


export async function deleteAppointment(appointmentId: string): Promise<void> {
    try {
        const privateDataCollectionRef = collection(db, 'appointments', appointmentId, 'privateData');
        const privateDataSnapshot = await getDocs(privateDataCollectionRef);
        
        privateDataSnapshot.forEach(async (document) => {
            await deleteDoc(doc(db, "appointments", appointmentId, 'privateData', document.id));
        });
        await deleteDoc(doc(db, "appointments", appointmentId));
        console.log("Appointment successfully deleted!");
        }

    catch (error) {
        console.error("Error deleting appointment: ", error);
    }
}

// Function to get appointment info
export async function getAppointmentInfo(shop_id, shopOwner=false): Promise<Appointment[]> {
    try {
    const q = query(collection(db, "appointments"), where("shop_id", "==", shop_id));
    const querySnapshot = await getDocs(q);

    const appointmentList: Appointment[] = [];
    const appointmentsWithPrivateData = await Promise.all(querySnapshot.docs.map(async (doc) => {
        let privateData = null
        var userShopOwned = null
        if (auth.currentUser !== null) {
            userShopOwned = (await getUserInfo()).shopOwned

            if ((doc.data().client_id === auth.currentUser.uid) || (userShopOwned === doc.data().shop_id)) {
                const privateDataRef = collection(doc.ref, "privateData");
                const privateDataSnapshot = await getDocs(privateDataRef);
                privateData = privateDataSnapshot.docs[0].data();
            }
        }

        if (shopOwner) {
            title = doc.data().title
            if (title === 'Blocked Hours') {
                backgroundColor = 'red'
            }
            backgroundColor = (privateData?.client_name === 'Blocked Hours') ? '#fe0101a8' : 'blue'
        }
        else{
            var backgroundColor = 'gray'
            var title = 'unavailable'
            if (auth.currentUser !== null) {
                if (auth.currentUser.uid === doc.data().client_id) {
                    backgroundColor = 'blue'
                    title = doc.data().title
                }
            }
        }
        let addAppointment = {
            id: doc.id,
            title: title,
            start: doc.data().start.toDate(),
            end: doc.data().end.toDate(),
            shop_id: doc.data().shop_id,
            client_name: privateData?.client_name ?? '',
            client_phone: privateData?.client_phone ?? '',
            client_requests: privateData?.client_requests ?? '',
            service_name: privateData?.service_name ?? '',
            backgroundColor: backgroundColor,

        }
        appointmentList.push(addAppointment as Appointment)
        
    }));
    return appointmentList;

    } catch (error) {
        console.error("Error getting user information: ", error);
        return [];
    }
}

export const getUserAppointments = async () => {
    try {
        const q = query(collection(db, "appointments"), where("client_id", "==", auth.currentUser.uid));
        const querySnapshot = await getDocs(q);
        const appointmentList: Appointment[] = [];

        const appointmentsWithPrivateData = await Promise.all(querySnapshot.docs.map(async (doc) => {
            let privateData = null
            const userShopOwned = (await getUserInfo()).shopOwned
            if ((doc.data().client_id === auth.currentUser.uid) || (userShopOwned === doc.data().shop_id)) {
                const privateDataRef = collection(doc.ref, "privateData");
                const privateDataSnapshot = await getDocs(privateDataRef);
                privateData = privateDataSnapshot.docs[0].data();
            }
            var backgroundColor = 'gray'
            var title = 'unavailable'
            if (auth.currentUser.uid === doc.data().client_id) {
                backgroundColor = 'blue'
                title = doc.data().title
            }
            
            let addAppointment = {
                id: doc.id,
                title: title,
                start: doc.data().start.toDate(),
                end: doc.data().end.toDate(),
                shop_id: doc.data().shop_id,
                client_name: privateData?.client_name ?? '',
                client_phone: privateData?.client_phone ?? '',
                client_requests: privateData?.client_requests ?? '',
                service_name: privateData?.service_name ?? '',
                backgroundColor: backgroundColor,

            }
            appointmentList.push(addAppointment as Appointment)
            
        }));
        return appointmentList;
    } catch (error) {
        console.error("Error getting user information: ", error);
        return [];
    }
}