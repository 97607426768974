import React, { useEffect, useState, useRef } from 'react';
import ResponsiveAppBar from '../../../components/appbar/Appbar';
import { getPublicShopInfo, ShopInfo } from '../../../resources/shopsDB/ShopsDB';
import '../../../App.css';
import TextField from '@mui/material/TextField';
import { Button, ToggleButton, Box } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import ShopCard from '../../../Pages/HomePage/ShopCard';
import { handleFileUpload } from '../../../components/auth/firebase';
import GooglePlacesAutocomplete from './GeocodeAutocomplete'
import { updateShopInfo } from '../../../resources/shopsDB/ShopsDB';
import Alert, { AlertColor } from '@mui/material/Alert'; // Import Alert component
import Snackbar from '@mui/material/Snackbar';
import { getUserInfo } from '../../../resources/usersDB/UsersDB';
import { useNavigate } from 'react-router-dom';











const ShopSettingsPage: React.FC = () => {
    const mountedRef = useRef(false)
    const [shopInfo, setShopInfo] = useState<ShopInfo | null>(null);
    const [nameFieldValue, setnameFieldValue] = useState('');
    const [addressFieldValue, setaddressFieldValue] = useState('');
    const [telephoneFieldValue, settelephoneFieldValue] = useState('');
    const [emailFieldValue, setemailFieldValue] = useState('');
    const [facebookFieldValue, setfacebookFieldValue] = useState('');
    const [instagramFieldValue, setinstagramFieldValue] = useState('');
    const [headerimageFieldValue, setheaderimageFieldValue] = useState('');
    const [shopAddress, setShopAddress] = useState({address: '', geometry: {_lat: 0, _long: 0,}});

    // const [alertMessage, setAlertMessage] = useState<string | null>(null);
    // const [alertType, setAlertType] = useState<string>("error");
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    
    const alertType = useRef("error");
    const alertMessage = useRef("Error fetching shop information");





    useEffect(() => {
        void getPublicShopInfo(null).then(shop => {
            setnameFieldValue(shop ? shop.name : '');
            settelephoneFieldValue(shop ? shop.phone.slice(4, shop.phone.length) : '');
            setaddressFieldValue(shop ? shop.address : '');
            setemailFieldValue(shop ? shop.email : '');
            setfacebookFieldValue(shop ? shop.facebook : '');
            setinstagramFieldValue(shop ? shop.instagram : '');
            setheaderimageFieldValue(shop ? shop.header_image : '');
            setShopInfo({
                address: shop ? shop.address : '',
                email: shop ? shop.email : '',
                facebook: shop ? shop.facebook : '',
                geometry: shop ? shop.geometry : {_lat: 0, _long: 0,},
                header_image: shop ? shop.header_image : '',
                instagram: shop ? shop.instagram : '',
                name: shop ? shop.name : '',
                phone: shop ? shop.phone : '',
                shop_services: shop ? shop.shop_services : [],
                shopweeklyschedule: shop ? shop.shopweeklyschedule : [],
            } as ShopInfo);
            setShopAddress({address: shop ? shop.address : '', geometry: shop ? shop.geometry : {_lat: 0, _long: 0,}});
            mountedRef.current = true;
        })
        
    }, []);

    useEffect(() => {
        if (mountedRef.current){
            if (shopInfo) {
                setnameFieldValue(shopInfo.name);
                settelephoneFieldValue(shopInfo.phone.slice(4, shopInfo.phone.length));
                setaddressFieldValue(shopInfo.address);
                setemailFieldValue(shopInfo.email);
                setfacebookFieldValue(shopInfo.facebook);
                setinstagramFieldValue(shopInfo.instagram);
                setheaderimageFieldValue(shopInfo.header_image);
                // setShopAddress({address: shopInfo.address, geometry: shopInfo.geometry});
            }
            setLoading(false);
        }
    }, [shopInfo]);

    useEffect(() => {
        if (imageUrl) {
            setShopInfo({ ...shopInfo, header_image: imageUrl });
        }
    }, [imageUrl]);

    useEffect(() => {
        setShopInfo({...shopInfo, name: nameFieldValue, phone: `+972${telephoneFieldValue}`, email: emailFieldValue, facebook: facebookFieldValue, instagram: instagramFieldValue, address: shopAddress.address, geometry: shopAddress.geometry });
    }, [shopAddress]);


    const handleNameFieldChange = (event) => {
        setnameFieldValue(event.target.value);
    };


    const handletelephoneFieldChange = (event) => {
        settelephoneFieldValue(event.target.value);
    };

    const handleEmailFieldChange = (event) => {
        setemailFieldValue(event.target.value);
    };
    
    const handleFacebookFieldChange = (event) => {
        setfacebookFieldValue(event.target.value);
    };

    const handleInstagramFieldChange = (event) => {
        setinstagramFieldValue(event.target.value);
    };


    const submitShopInfo = async () => {
        var varShopInfo = {
            name: nameFieldValue,
            address: shopInfo.address,
            geometry: shopInfo.geometry,
            header_image: shopInfo.header_image,
            email: emailFieldValue,
            phone: telephoneFieldValue,
            instagram: instagramFieldValue,
        }
        if (typeof parseInt(telephoneFieldValue) !== 'number' || isNaN(parseInt(telephoneFieldValue))) {
            alertType.current = "error"
            alertMessage.current = "נדרש מספר טלפון חוקי";
            setSnackbarOpen(true);
            return;
        }
        
        if (telephoneFieldValue.length < 9) {
            alertMessage.current = "נדרש לפחות 9 ספרות במספר הטלפון";
            setSnackbarOpen(true);
            return;
        }
        if (telephoneFieldValue.length == 10) {
            varShopInfo.phone = `+972${telephoneFieldValue.substr(1,)}`;
        }
        if (nameFieldValue.length == 0) {
            alertType.current = "error"
            alertMessage.current = "נדרש שם החנות";
            setSnackbarOpen(true);
            return;
        }
        if (varShopInfo.address.length == 0) {
            alertType.current = "error"
            alertMessage.current = "נדרש כתובת החנות";
            setSnackbarOpen(true);
            return;
        }




        

        // Proceed with updating user info
        alertType.current = "success"
        alertMessage.current = "עודכן פרטי החנות"; // Clear alert message if update is successful
        setSnackbarOpen(true);
        const shopID = await updateShopInfo(varShopInfo as ShopInfo);
        navigate(`/shop/${shopID}`);

    }


    const onClickCard = (event, ShopInfo: ShopInfo) => {
        // Create hidden file input
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*'; // Optional: limit to image files
        
        // Handle file selection
        fileInput.onchange = (e) => {
        const target = e.target as HTMLInputElement;
        const file = target.files?.[0];
        if (!file) return;
        
        console.log('Selected file:', file);

        handleFileUpload([file], setImageUrl);
        };

        // Trigger file picker
        fileInput.click();
    }
    
    if (loading) {
        return <div>Loading...</div>;
    }
    else {
        return (
            <div>
                <ResponsiveAppBar />
                <Box
                    display="flex"
                    justifyContent="space-around"
                    alignItems="center"
                    flexDirection="column"
                    flexWrap="wrap"
                    sx={{ gap: 2, padding: 2 }}
                >
                <Button onClick={() => {submitShopInfo()}}
                    variant="contained"
                    sx={{
                    flexGrow: 6,
                    minWidth: 100,
                    textAlign: 'center',
                    background: 'linear-gradient(90deg, #b62a73 1%, #ffcdb3 100%)',
                    }}
                    >
                    עדכן פרטים
                </Button>
                <div style={{
                    width: '50%',
                    // // Ensure each grid item stretches to fill the grid cell
                    // '& > div': {
                    // height: '100%', // Make sure the div inside each grid cell takes full height
                    // display: 'flex', // Use flexbox for full coverage
                    // },
                }}>
                    <ShopCard shopInfo={shopInfo} handleOnClick={onClickCard}/>
                </div>
                <TextField
                    autoFocus
                    fullWidth 
                    margin="dense"
                    id="name"
                    name="name"
                    value={nameFieldValue}
                    onChange={handleNameFieldChange}
                    label="שם החנות"
                    variant="outlined"
                    required
                />
                <TextField
                    label="Phone Number"
                    variant="outlined"
                    value={telephoneFieldValue}
                    onChange={handletelephoneFieldChange}
                    placeholder="Phone Number"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">+972</InputAdornment>,
                    }}
                    inputProps={{
                    maxLength: 10,
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    }}
                    required
                />
                <GooglePlacesAutocomplete Info={shopAddress} setInfo={setShopAddress} required={true}/>
                <TextField
                    autoFocus
                    fullWidth 
                    margin="dense"
                    id="facebook"
                    name="facebook"
                    value={facebookFieldValue}
                    onChange={handleFacebookFieldChange}
                    label="Facebook"
                    variant="outlined"
                />
                <TextField
                    autoFocus
                    fullWidth 
                    margin="dense"
                    id="instagram"
                    name="instagram"
                    value={instagramFieldValue}
                    onChange={handleInstagramFieldChange}
                    label="Instagram Handle"
                    variant="outlined"
                />
                <TextField
                    autoFocus
                    fullWidth 
                    margin="dense"
                    id="email"
                    name="email"
                    value={emailFieldValue}
                    onChange={handleEmailFieldChange}
                    label="מייל"
                    variant="outlined"
                />
                </Box>
                <Snackbar
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                                open={snackbarOpen}
                                                autoHideDuration={1500}
                                                onClose={() => { setSnackbarOpen(false) }}
                                                // message={snackbarMessage}
                                                // key={vertical + horizontal}
                                              >
                                        <Alert  severity={alertType.current as AlertColor} onClose={() => { }}>
                                            {alertMessage.current}
                                        </Alert>
                </Snackbar>
            </div>
        )
    }
}


export default ShopSettingsPage;