import React, { useState, useEffect } from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import { firebaseStorage } from './firebase';

export const fetchBucketImage = async (setImageUrl, imagePath) => {
    try {
      if (imagePath) {
      // Create a reference to the image file
      const imageRef = ref(firebaseStorage, imagePath);
      
      // Get the download URL
      const url = await getDownloadURL(imageRef);
      
      setImageUrl(url);
      }
      else {
        setImageUrl('./upload-image-icon-50.png');
      }
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };


  export const returnBucketImage = async (imagePath) => {
    try {
      // Create a reference to the image file
      const imageRef = ref(firebaseStorage, imagePath);
      
      // Get the download URL
      const url = await getDownloadURL(imageRef);
      
      return(url);
    } catch (error) {
      console.error('Error fetching image:', error);
      return ''
    }
  };