import { db } from "../../components/auth/firebase";
import { doc, setDoc, getDoc, collection, getDocs, updateDoc } from 'firebase/firestore';
import { auth } from "../../components/auth/firebase";
import { ex } from "@fullcalendar/core/internal-common";


export interface UserInfo {
    first_name: string;
    last_name: string;
    photoURL: string;
    address: string
    email: string;
    phone_number: string;
    instagram: string;
    shopOwned: string;
}

// export interface UserData {
//     id: string;
//     role: string;
// }

// Function to store user info
export async function storeUserInfo(userId: string, userInfo: UserInfo): Promise<void> {
    try {
        await setDoc(doc(db, "users", userId), userInfo);
        console.log("User information successfully stored!");
    } catch (error) {
        console.error("Error storing user information: ", error);
    }
    }

// Function to get user info
export async function getUserInfo(): Promise<UserInfo | null> {
    try {
        if (auth.currentUser) {
            const docSnap = await getDoc(doc(db, "users", auth.currentUser.uid));
            if (docSnap.exists()) {
                console.log("User found");
            } else {
                await storeUserInfo(auth.currentUser.uid, {"email": auth.currentUser.email, "address": "", "phone_number": "", "shopOwned": "", "first_name": "", "last_name": "", "photoURL": "", "instagram": ""});
                console.log("User Created!");
            }
            const userData: UserInfo = {
                first_name: docSnap.data().first_name ? docSnap.data().first_name : "",
                last_name: docSnap.data().last_name ? docSnap.data().last_name : "",
                photoURL: docSnap.data().photoURL ? docSnap.data().photoURL : "",
                address: docSnap.data().address? docSnap.data().address : "",
                email: docSnap.data().email ? docSnap.data().email : "",
                phone_number: docSnap.data().phone_number ? docSnap.data().phone_number : "",
                instagram: docSnap.data().instagram ? docSnap.data().instagram : "",
                shopOwned: docSnap.data().shopOwned ? docSnap.data().shopOwned : "",
            }
            return userData;
        }
    } catch (error) {
        console.error("Error getting user information: ", error);
        return null;
    }
}

export async function updateUserInfo(userInfo: UserInfo): Promise<void> {
    try {
        const docSnap = await getDoc(doc(db, "users", auth.currentUser.uid));
        await updateDoc(docSnap.ref, userInfo as object);
        console.log("User information successfully updated!");
    } catch (error) {
        console.error("Error updating user information: ", error);
    }
}