import React, { useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import { ShopInfo } from '../../../resources/shopsDB/ShopsDB';
import { UserInfo } from '../../../resources/usersDB/UsersDB';

interface Coordinates {
  _lat: number;
  _long: number;
}

interface Place {
  address: string;
  coordinates: Coordinates;
}

interface CommonInfo {
  address: string;
}

interface Props {
  Info, //: ShopInfo | UserInfo; // Use union type for either ShopInfo or UserInfo
  setInfo, //: (info: ShopInfo | UserInfo) => void;
  required: boolean;
}

const GooglePlacesAutocomplete: React.FC<Props> = ({ Info, setInfo, required }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const [error, setError] = useState<string>('');
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (window.google && !initialized) {
      setInitialized(true);
      if (inputRef.current) {
        autocompleteRef.current = new google.maps.places.Autocomplete(inputRef.current, {
          fields: ['formatted_address', 'geometry'],
          types: ['address']
        });
        autocompleteRef.current.addListener('place_changed', handlePlaceSelect);
      }
    }
  }, [initialized]);

  const handlePlaceSelect = () => {
    const place = autocompleteRef.current?.getPlace();
    
    if (!place?.geometry?.location) {
      setError('Please select an address from the dropdown');
      return;
    }

    const selectedPlace: Place = {
      address: place.formatted_address || '',
      coordinates: {
        _lat: place.geometry.location.lat(),
        _long: place.geometry.location.lng()
      }
    };

    setError('');

    // Type guard to determine if Info is ShopInfo or UserInfo
    if ('geometry' in Info) {
      // If Info is of type ShopInfo
      setInfo({ ...Info, address: selectedPlace.address, geometry: selectedPlace.coordinates });
    } else {
      // If Info is of type UserInfo
      setInfo({ ...Info, address: selectedPlace.address });
    }
  };

  return (
    <TextField
      label="כתובת"
      variant="outlined"
      fullWidth
      inputRef={inputRef}
      defaultValue={Info.address}
      error={!!error}
      helperText={error}
      // multiline
      minRows={2}
      required={required}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default GooglePlacesAutocomplete;