import React, { useEffect, useState } from 'react';
import ResponsiveAppBar from '../../../components/appbar/Appbar';
import ShopServices from '../../../components/shopservices/ShopServices';
import { Service } from '../../../resources/shopsDB/ShopsDB';
import { auth } from '../../../components/auth/firebase';
import { Grid } from '@mui/material';
import IconRow from '../../../components/iconrow/IconRow';
import { getPublicShopInfo, ShopInfo } from '../../../resources/shopsDB/ShopsDB';
import '../../../App.css';
import ShopCalendar from '../../../components/shopCalendar/ShopCalendar';
import OvalCard from '../../../components/shopservices/OvalCard';
import { removeShopService } from '../../../resources/shopsDB/ShopsDB';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import FormControl from '@mui/material/FormControl';
import { handleFileUpload, deleteFileByDownloadUrl } from '../../../components/auth/firebase';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';




const ShopServicesPage: React.FC = () => {
    const [selectedService, setSelectedService] = useState<Service | null>(null);
    const [shopInfo, setShopInfo] = useState<ShopInfo | null>(null);
    const [confirmWindowOpen, setConfirmWindowOpen] = useState(false);
    const [loading, setLoading] = useState(true);


    const getShopInfo = async () => {
        const shopData: ShopInfo = await getPublicShopInfo(null)
        setShopInfo(shopData);
    }

    const removeShopServiceWrapper = async (service: Service) => {
          setLoading(true);
          await removeShopService(service);
          void getShopInfo();
          setLoading(false);

    }
  


    
    
    
    useEffect(() => {
        void getShopInfo();
        setLoading(false);
    }, []);


    if (loading) {
        return (
            <div>
                <ResponsiveAppBar />
                <h1>Loading...</h1>
            </div>
        );
    }
    else {
      return (
          <div>
              <ResponsiveAppBar />
              <ShopServices shopInfo={shopInfo} selectedService={selectedService} onClick={(service)=>{setSelectedService(service); setConfirmWindowOpen(true)}} addService={true}/>

              <React.Fragment>
                <Dialog
                  open={confirmWindowOpen}
                  keepMounted
                  onClose={() => { setConfirmWindowOpen(false) }}
                  aria-describedby="alert-dialog-slide-description"
                >
                  {/* <DialogTitle>{"Book an Appointment"}</DialogTitle> */}
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    בטוחה שתרצי למחוק את השירות {selectedService?.name}
                    </DialogContentText>
                  </DialogContent>
                  
                  <DialogActions>
                    <Button onClick={() => { setConfirmWindowOpen(false) }}>Cancel</Button>
                    <Button onClick={() => { removeShopServiceWrapper(selectedService!); setConfirmWindowOpen(false) }}>Agree</Button>
                  </DialogActions>
                </Dialog>
              </React.Fragment>
          </div>
      )
    }
}


export default ShopServicesPage;