import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ResponsiveAppBar from '../../components/appbar/Appbar';
import ShopOwnerCalendar from '../../components/shopOwnerCalendar/ShopOwnerCalendar';
import { doc, getDoc } from 'firebase/firestore'; 
import { getUserInfo } from '../../resources/usersDB/UsersDB';
import { NailSalonFeature } from '../Map/nail_salons';
import { auth, db } from '../../components/auth/firebase';
import { Grid } from '@mui/material';
import { ToggleButton } from '@mui/material';
import { Button } from '@mui/material';
import ButtonGrid from '../../components/buttongrid/ButtonGrid';
import '../../App.css';






const ShopOwnerPage: React.FC = () => {

  
  const [shopInfo, setShopInfo] = useState<NailSalonFeature>({
    type: 'Feature',
    id: '',
    geometry: {
      type: 'Point',
      coordinates: [0, 0]
    },
    properties: {
      name: 'placeholder',
      address: 'placeholder',
      instagram: ''
    }
    });

  const [IsLoading, setIsLoading] = useState<boolean>(true);

  
  const getShopInfo = async () => {
    const UserData = await getUserInfo()
    const shopsSnapshot = await getDoc(doc(db, "shops", UserData.shopOwned));
  
    const shopData : NailSalonFeature = {
      type: 'Feature',
      id: shopsSnapshot.id,
      geometry: {
        type: 'Point',
        coordinates: [shopsSnapshot.data().geometry._long, shopsSnapshot.data().geometry._lat]
      },
      properties: {
        name: shopsSnapshot.data().name,
        address: shopsSnapshot.data().address,
        instagram: shopsSnapshot.data().instagram
      }
      }
    await setShopInfo(shopData);
    await setIsLoading(false)
  }

  useEffect(() => {
    void getShopInfo();
  }, []);


  if (IsLoading) {
    return (
      <div>
        <ResponsiveAppBar />
          <p>Loading...</p>
      </div>
    );
  }
  else {
    return (
      <div style={{flexGrow: '1', flexDirection: 'column', justifyContent: 'space-between', background: "#fff8f5", textAlign: 'center'}}>
        <ResponsiveAppBar />
        <ButtonGrid />
        <div className="lilac-calendar-container">
          <ShopOwnerCalendar shopId={shopInfo.id}/>
        </div>      
      </div>
    );
}

};

export default ShopOwnerPage;